@import "abstract/variables";

@import "base/animations";
@import "base/font";
@import "base/base";

@import "components/element";
@import "components/code-pen";

.content-container {
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.main-header {
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;

  background-color: var(--secondary_background_color);
  text-align: center;
  padding: 20px 30px 20px;

  &.open {
    .ask-enquiry,
    .logo-frame,
    .left-side {
      filter: blur(2px);
    }
  }

  @media (max-width: 991px) {
    padding: 20px 10px;

    h2,
    h4,
    h5 {
      display: none;
    }

    .logo-frame {
      width: 80px !important;
      height: 40px !important;
    }
  }

  .left-side {
    display: flex;
    align-items: center;
    gap: 3em;

    @media (max-width: 991px) {
      gap: 1.5em;
    }
  }

  .logo-frame {
    width: 120px;
    height: 60px;
  }

  .cart-frame {
    position: relative;

    &.--active {
      svg {
        color: #fb3318;
      }

      .cart-count {
        font-family: "montserrat-semibold";
        color: #fb3318 !important;
        background: #ffffff !important;
      }
    }

    svg {
      width: 30px;
      height: 30px;
      color: var(--secondary_font_color);
    }

    .cart-count {
      font-size: 11px;
      color: #ffffff;
      background: #fb3318;
      border-radius: 30px;
      padding: 0px 7px;
      width: max-content;
      position: absolute;
      right: -8px;
      top: -2px;
    }
  }

  .login-button {
    font-size: 17px;
    text-transform: uppercase !important;
    white-space: pre !important;
    padding: 9px 21px !important;
    background: var(--secondary_button_color) !important;

    @media (max-width: 991px) {
      font-size: 13px !important;
    }
  }

  svg {
    width: 27px;
    height: 27px;
    color: var(--secondary_font_color);
  }

  h2,
  h4,
  h5 {
    font-weight: unset !important;
    margin-bottom: 0;
  }

  h2 {
    color: var(--secondary_font_color);
    font-family: "arial-bold";
    font-size: 32px;
  }

  h4 {
    color: var(--tertiary_font_color);
    font-family: "arial-bold";
    font-size: 18px;
  }

  h5 {
    color: var(--secondary_font_color);
    font-family: "arial";
    font-size: 18px;

    &.--active {
      color: #fb3318;
      font-family: "arial-bold";
      text-shadow: 2px 3px 4px #ff000024;
    }
  }

  .menu-bar {
    margin-top: 0.1em;
    background: var(--secondary_background_color);
    padding: 0.8em 0em 0.8em;

    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    z-index: 10;
    transition: all 0.4s ease;

    &.open {
      background: #1e1f26;
      padding: 0.8em 1em 0.8em;
    }
  }

  .menu-list {
    display: flex;
    flex-direction: column;
    border-top-left-radius: 7px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    right: 0;
    top: 3.5em;
    width: max-content;
    position: absolute;

    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    // transform: translate(-50%, -50%);
    background: #1e1f26;

    &.open {
      opacity: 1;
      max-height: 150px;

      @media (max-width: 991px) {
        max-height: 300px;
      }

      p,
      svg {
        opacity: 1;
      }
    }

    .menu-item {
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 13px 15px;
      min-width: 170px;

      &.active {
        background: #ed1c240f;

        p {
          font-family: "montserrat-semibold";
          color: var(--primary_color);
        }

        svg {
          color: var(--primary_color);
        }
      }

      &:hover {
        background: var(--secondary_background_color);

        p {
          font-family: "montserrat-medium";
          color: var(--primary_font_color);
        }

        svg {
          color: var(--secondary_font_color);
        }
      }
    }

    p {
      margin-top: 0.1em;
      margin-bottom: 0;
      opacity: 0.5;
      font-size: 14px;
      color: var(--secondary_font_color);
      font-family: var(--primary_font);
    }

    svg {
      width: 18px;
      height: 18px;
      color: var(--secondary_font_color);
    }
  }
}

.footer {
  background: var(--secondary_background_color);
  padding: 90px 0 25px;

  @media screen and (max-width: 1300px) {
    padding: 80px 0 25px;
  }

  @media screen and (max-width: 550px) {
    padding: 80px 0 0;
  }

  .footer-sublist {
    display: flex;
    gap: 100px;

    &:nth-child(2) {
      margin: 0 100px;

      @media screen and (max-width: 1300px) {
        margin: 5px 100px;
        width: 100%;
        display: grid;
        place-items: center;
      }

      @media screen and (max-width: 550px) {
        order: 1 !important;
        margin: 20px 100px 10px;
      }
    }

    @media screen and (max-width: 550px) {
      justify-content: center;
      flex-wrap: wrap;
      column-gap: 40px;
      row-gap: 30px;
    }
  }

  .footer-list {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1300px) {
      flex-wrap: wrap;
      gap: 50px;
    }

    @media screen and (max-width: 550px) {
      column-gap: 50px;
      row-gap: 30px;
    }

    .footer-item {
      p {
        font-family: var(--secondary_font);
        color: var(--primary_font_color);
        margin-bottom: 0;
        font-size: 18px;

        @media screen and (max-width: 550px) {
          font-size: 16px;
        }
      }
    }

    .footer-frame {
      width: 120px;
      height: 60px;
    }
  }

  hr {
    opacity: 1;
    border-top-width: 2px;
    border-top-color: var(--secondary_font_color);
    margin: 3em 0 8em;

    @media screen and (max-width: 550px) {
      margin: 3em 0 6em;
    }
  }

  .social-media-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    padding-bottom: 2em;

    @media screen and (max-width: 550px) {
      padding: 0 1em 1em;
    }

    p {
      font-family: var(--primary_font);
      color: var(--secondary_font_color);
      margin-bottom: 0;
      font-size: 15px;

      @media screen and (max-width: 550px) {
        font-size: 12px;
      }
    }
  }
}

.page-padding {
  padding: 150px 0 100px;
}

.home-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding-top: 0px !important;

  .why-justint {
    width: 100%;
    // max-width: 1152px;
    max-width: 1152px;
    text-align: center;
    display: flex;
    justify-content: center;
    // flex-direction: column;
    // gap: 30px;

    @media screen and (max-width: 750px) {
      flex-wrap: wrap;
      position: relative;
    }

    .why-frame {
      width: 45%;

      @media screen and (max-width: 991px) {
        width: 60%;
      }

      @media screen and (max-width: 750px) {
        width: 100%;
        min-height: 550px;
      }
    }

    .why-text {
      height: 50%;
      width: 55%;
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 22px;
      border-radius: 15px;
      // border: 1px solid #d9d9d9;
      box-shadow: 0 4px 20px #0000001f;
      padding: 5em 5em 5em 3em;
      margin-left: -5em;
      margin-top: 4em;
      margin-bottom: 6em;
      background: #ffffff99;
      backdrop-filter: blur(3px);

      @media screen and (max-width: 991px) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 60%;
        gap: 18px;
      }

      @media screen and (max-width: 750px) {
        margin: auto !important;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        padding: 4em 3em;
        width: 95%;
        height: max-content;
        position: absolute;
        bottom: 10%;
        background: #ffffffd8;
      }
    }
  }

  .one-stop-solution {
    display: flex;
    justify-content: center;
    background: var(--secondary_background_color);
    padding: 5em 0 5em 5em;
    width: 100%;
    margin-top: 5em;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 991px) {
      padding: 5em 0 5em 2em;
    }

    @media screen and (max-width: 750px) {
      margin-top: -5em;
    }

    .one-stop-layout {
      width: 100%;
      gap: 5px;
      display: flex;
      flex-direction: column;
      max-width: 1512px;
    }

    h2,
    h4,
    p {
      margin-bottom: 0;
      font-family: var(--primary_font);
    }

    h2 {
      color: #ffffff;
      font-size: 50px;
      font-family: "montserrat-semibold";

      @media screen and (max-width: 991px) {
        font-size: 40px;
      }

      @media screen and (max-width: 550px) {
        font-size: 30px;
      }
    }

    h4 {
      color: var(--secondary_font_color);
      font-size: 36px;

      @media screen and (max-width: 991px) {
        font-size: 18px;
        line-height: 1.6em;
      }
    }

    p {
      display: flex;
      align-items: center;
      gap: 1em;
      color: #dcdcdc;
      font-size: 16px;
      font-style: italic;
      text-transform: uppercase;
      font-family: "montserrat";

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }

      @media screen and (max-width: 550px) {
        font-size: 13px;
      }

      &.--subtitle {
        font-style: normal;
        text-transform: none;
        text-align: justify;
        padding: 0 0 0 5em;
        font-size: 18px;

        @media screen and (max-width: 991px) {
          padding: 0;
          font-size: 15px;
        }

        @media screen and (max-width: 550px) {
          font-size: 13px;
        }
      }

      span {
        display: block;
        width: 3.5em;
        height: 1px;
        background: #999999;
      }
    }

    .max-w-100 {
      max-width: 100%;
    }

    .one-stop-text {
      display: grid;
      align-items: center;
      grid-template-columns: 0.75fr 1fr;
      gap: 50px;
      padding-right: 10em;

      @media screen and (max-width: 991px) {
        gap: 22px;
        padding-right: 2em;
        grid-template-columns: 1fr;
      }
    }

    .one-stop-list {
      gap: 150px;
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 1.2em;
      padding-right: 150px;

      @media screen and (max-width: 991px) {
        gap: 80px;
        padding-right: 100px;
      }

      @media screen and (max-width: 550px) {
        gap: 0;
        padding-right: 0px;
      }

      .previous-button {
        width: 70px;
        height: 68px;
        border: 1px solid #dcdcdc;
        border-radius: 100%;
        background: transparent;

        @media screen and (max-width: 550px) {
          display: none;
        }

        .left-arrow {
          width: 100%;
          display: flex;
          align-items: center;
          margin-left: 2.5em;
        }

        .arrow {
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-right: 8px solid #dcdcdc;
        }

        .line {
          width: 100%;
          height: 1px;
          background: #dcdcdc;
          margin-bottom: 0.1em;
        }
      }

      .one-stop-item {
        width: 380px;
        height: 450px;
        position: relative;

        @media screen and (max-width: 550px) {
          width: 300px;
          height: 350px;
        }
      }

      .one-stop-frame {
        width: 100%;
        height: 100%;

        img {
          filter: brightness(0.9);
        }
      }

      .one-stop-desc {
        bottom: 0;
        padding: 1.8em 2em 1.8em;
        position: absolute;
        display: flex;
        flex-direction: column;
        background: #000000a3;
        gap: 10px;
        backdrop-filter: blur(3px);

        @media screen and (max-width: 550px) {
          gap: 5px;
        }

        h4 {
          font-size: 25px;
          color: #ffffff;
          font-family: "montserrat-medium";

          @media screen and (max-width: 550px) {
            font-size: 20px;
          }
        }

        p {
          color: #ffffff;
          font-style: normal;
          text-transform: unset;
          line-height: 1.5em;
        }
      }
    }
  }

  // .unknown-section {
  //     width: 100%;
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     max-width: 1152px;
  //     gap: 30px;

  //     @media screen and (max-width: 1200px) {
  //         width: 90%;
  //         justify-content: center;
  //         margin-left: auto;
  //         margin-right: auto;
  //         flex-wrap: wrap;
  //         gap: 20px;
  //     }

  //     @media screen and (max-width: 600px) {
  //         gap: 20px;
  //     }

  //     .unknown-item {
  //         width: 100%;
  //         text-align: center;
  //         display: flex;
  //         flex-direction: column;
  //         gap: 6px;
  //         border-radius: 18px;
  //         padding: 30px 25px;
  //         background: var(--secondary_background_color);

  //         @media screen and (max-width: 1200px) {
  //             width: 48%;
  //             padding: 20px 25px;
  //         }

  //         @media screen and (max-width: 600px) {
  //             width: 100%;
  //             padding: 20px 25px;
  //         }

  //         h2, p {
  //             margin-bottom: 0;
  //             font-family: var(--primary_font);
  //         }

  //         h2 {
  //             font-size: 44px;
  //             line-height: 53.64px;
  //             font-family: var(--secondary_font);
  //             color: var(--primary_font_color);

  //             @media screen and (max-width: 991px) {
  //                 font-size: 36px;
  //             }
  //         }

  //         p {
  //             font-family: var(--primary_font);
  //             color: var(--secondary_font_color);
  //             font-size: 18px;

  //             @media screen and (max-width: 991px) {
  //                 font-size: 16px;
  //             }
  //         }
  //     }
  // }

  .car-product {
    padding: 5em 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    gap: 30px;

    @media screen and (max-width: 1200px) {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 550px) {
      padding-bottom: 0;
    }

    h2,
    p {
      margin-bottom: 0;
      color: var(--primary_font_color);
    }

    h2 {
      font-family: var(--primary_font);
      font-size: 30px;

      @media screen and (max-width: 991px) {
        font-size: 25px;
      }

      @media screen and (max-width: 550px) {
        font-size: 22px;
      }
    }

    .car-grid {
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      gap: 30px;
    }

    .image-grid {
      @media screen and (max-width: 1200px) {
        grid-column: span 2;
        // max-height: 300px;
      }
    }

    .car-frame {
      position: relative;
      width: 100%;
      height: 100%;
      background: transparent;
      border-radius: 8px;

      @media screen and (max-width: 1200px) {
        height: 400px;
      }

      video {
        height: 100%;
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
        filter: hue-rotate(150deg) saturate(0.3) brightness(0.9);
      }
    }

    .car-text {
      position: absolute;
      top: 0;
      padding: 30px 25px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media screen and (max-width: 991px) {
        gap: 12px;
        padding: 20px 25px;
      }

      h6,
      p {
        margin-bottom: 0;
        color: var(--primary_font_color);

        @media screen and (max-width: 1200px) {
          color: #ffffff !important;
        }
      }

      h6 {
        font-family: "montserrat-semibold";
        font-size: 18px;
        color: #ffffff;
        line-height: 30px;

        @media screen and (max-width: 991px) {
          font-size: 25px;
        }

        @media screen and (max-width: 550px) {
          font-size: 16px;
        }

        &.--regular {
          font-family: var(--primary_font);
        }
      }

      p {
        font-family: var(--primary_font);
        font-size: 15px;
        color: #dcdcdc;

        @media screen and (max-width: 991px) {
          font-size: 14px;
        }

        @media screen and (max-width: 550px) {
          font-size: 13px;
        }
      }

      span {
        text-transform: uppercase;
        font-family: var(--primary_font);
        font-size: 13px;
        color: #dcdcdc;
      }
    }

    .product-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;

      @media screen and (max-width: 1200px) {
        grid-column: span 2;
        gap: 25px;
      }

      @media screen and (max-width: 750px) {
        grid-template-columns: 1fr 1fr;
        gap: 20px;
      }

      .product-item {
        text-align: start;
        display: flex;
        flex-direction: column;
        gap: 18px;

        @media screen and (max-width: 750px) {
          &:nth-child(4),
          &:nth-child(5) {
            display: none !important;
          }
        }

        .product-frame {
          width: 250px;
          height: 260px;
          border-radius: 5px;
          background: var(--secondary_background_color);

          @media screen and (max-width: 1200px) {
            // padding: 23px;
            width: 100%;
          }

          @media screen and (max-width: 550px) {
            height: 180px;
          }

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        p {
          margin-bottom: 0;
        }

        h5 {
          font-family: var(--secondary_font);
          color: var(--secondary_font_color);
          font-size: 18px;

          @media screen and (max-width: 991px) {
            font-size: 16px;
          }

          @media screen and (max-width: 550px) {
            font-size: 14px;
          }
        }

        p {
          // display: flex;
          // justify-content: center;
          // align-items: baseline;
          gap: 5px;
          color: var(--secondary_font_color);
          font-family: var(--primary_font);
          font-size: 15px;

          @media screen and (max-width: 991px) {
            font-size: 14px;
          }

          @media screen and (max-width: 550px) {
            font-size: 13px;
          }

          &.--nrp {
            font-size: 13px;
            color: #9ea0b3;

            @media screen and (max-width: 550px) {
              font-size: 11px;
            }
          }
        }

        span {
          font-size: 12px;
          text-decoration: line-through;
          color: #bfbfbf;

          &.--view-price {
            font-size: 14px;
            text-decoration: unset !important;

            @media screen and (max-width: 991px) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  .business-partner {
    display: flex;
    flex-direction: column;
    gap: 80px;
    text-align: center;
    max-width: 1512px;

    @media screen and (max-width: 991px) {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }

    .partner-text {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h2,
      h4 {
        margin-bottom: 0;
        font-family: var(--primary_font);
      }

      h2 {
        color: var(--primary_font_color);
        font-size: 45px;

        @media screen and (max-width: 991px) {
          font-size: 35px;
        }

        @media screen and (max-width: 550px) {
          font-size: 25px;
        }
      }

      h4 {
        color: var(--secondary_font_color);
        font-size: 25px;
        line-height: 1.6em;

        @media screen and (max-width: 991px) {
          font-size: 20px;
        }

        @media screen and (max-width: 550px) {
          font-size: 15px;
        }
      }
    }

    .partner-marquee {
      gap: 80px;
    }

    .partner-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 56px;

      .partner-frame {
        width: 174px;
        height: 76px;
        filter: grayscale(1);
        transition: all 0.2s ease;

        &:hover {
          filter: unset;
        }

        @media screen and (max-width: 991px) {
          width: 120px;
          height: 53px;
        }
      }
    }
  }
}

.profile-commission-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  .profile-commission-form {
    max-width: 965px;
    background: var(--secondary_background_color);
    padding: 20px 0px;
    border-radius: 16px;
    width: 80%;
    box-shadow: 18px 21px 30px 0px #0000001f;

    @media screen and (max-width: 991px) {
      width: 90%;
    }

    h5,
    h6,
    p {
      font-size: 14px;
      margin-bottom: 0;
      color: var(--secondary_font_color);
    }

    h5 {
      padding: 0 20px;
      font-size: 22px;
      font-family: var(--primary_font);
      color: var(--primary_font_color);
    }

    h6 {
      width: 22%;
      font-family: "montserrat-semibold";

      @media screen and (max-width: 991px) {
        width: 35%;
      }

      @media screen and (max-width: 550px) {
        width: 55%;
      }

      &.--case-id {
        width: unset;
        font-family: var(--secondary_font);
      }

      &.--highlight {
        font-size: 18px;
      }

      &.--loading {
        color: #c4c3c3;
      }
    }

    p {
      font-family: var(--primary_font);

      &.--highlight {
        font-size: 18px;
        font-family: "montserrat-semibold";
      }
    }

    span {
      font-size: 14px;
      color: var(--secondary_font_color);
      font-family: var(--primary_font);
    }

    hr {
      opacity: 1;
      border-color: var(--primary_color);
      border-width: 3px;

      &.--grey {
        border-color: #373737;
        border-width: 2px;
      }
    }

    .form-list {
      padding: 15px 30px 5px;

      @media screen and (max-width: 991px) {
        padding: 15px 15px 5px;
      }
    }

    .form-item {
      &.--commission {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      &.disabled {
        label {
          opacity: 0.6 !important;
        }
      }
    }

    .info-list {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    svg {
      width: 19px;
      height: 19px;
      color: var(--primary_font_color);

      &.--file {
        width: 22px;
        height: 22px;
        margin-top: 0.1em;
        color: var(--primary_color);
      }
    }

    .profile-button {
      margin-top: 2em;
      padding: 10px 24px;
      background: var(--secondary_button_color);

      &:active {
        background: var(--secondary_button_color) !important;
      }

      &:disabled {
        opacity: 0.5;
        background: var(--secondary_button_color) !important;
      }
    }
  }
}

.order-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  .order-card {
    max-width: 965px;
    background: var(--secondary_background_color);
    padding: 20px 0px;
    border-radius: 16px;
    width: 80%;
    box-shadow: 18px 21px 30px 0px #0000001f;

    @media screen and (max-width: 991px) {
      width: 90%;
    }

    h5,
    h6,
    p {
      font-size: 15px;
      margin-bottom: 0;
      color: var(--secondary_font_color);

      @media (max-width: 991px) {
        font-size: 14px;
      }
    }

    h5 {
      padding: 0 20px;
      font-size: 22px;
      font-family: var(--primary_font);
      color: var(--primary_font_color);
    }

    h6 {
      color: var(--primary_font_color);
      font-family: "montserrat-semibold";

      &.--highlight {
        font-size: 18px;
      }

      &.--order {
        margin-bottom: 1em;
      }

      &.--order-id {
        font-family: "montserrat-semibold";
        font-size: 18px;

        @media (max-width: 991px) {
          font-size: 17px;
        }
      }

      &.--loading {
        color: #c4c3c3;
      }
    }

    p {
      font-family: var(--primary_font);

      &.--highlight {
        font-size: 18px;
        font-family: "montserrat-semibold";

        @media (max-width: 991px) {
          font-size: 16px;
        }
      }
    }

    span {
      font-size: 14px;
      color: #2e3a59;
      font-family: var(--primary_font);
    }

    hr {
      opacity: 1;
      border-color: var(--primary_color);
      border-width: 3px;

      &.--grey {
        border-color: #373737;
        border-width: 2px;
      }
    }

    // .search-frame {
    //     &.--open {
    //         width: 100%;
    //         height: 100%;
    //         padding: 0.5em;
    //         position: absolute;
    //         border-radius: 20px;
    //         background: #ffffff8c;
    //     }
    // }

    .search-bar {
      display: flex;
      margin: 0.5em 1.5em 0.5em 0;
      position: relative;
      gap: 1em;
      align-items: center;
      z-index: 1;
      padding: 0.35em;
      border-radius: 20px;
      background: #57585a;

      @media screen and (max-width: 820px) {
        width: 100%;
        margin: 1.6em 0 0.4em;

        .ant-picker-range {
          min-width: 230px !important;
          max-width: unset !important;
        }

        input {
          width: 100%;
        }
      }

      @media screen and (max-width: 550px) {
        flex-direction: column;
        border-radius: 12px;
        gap: 0.8em;
        padding: 1.4em 0.5em 0.5em;

        .ant-picker-range {
          .ant-picker-input {
            input {
              padding: 0;
            }
          }

          .ant-picker-clear {
            inset-inline-end: 11px !important;
          }
        }

        input {
          padding: 0 1em;
        }

        button {
          padding: 0.45em 0.7em !important;
          border-radius: 8px !important;

          svg {
            width: 17px !important;
            height: 17px !important;
            margin-bottom: 0.1em !important;
          }

          p {
            font-size: 14px !important;
            display: block !important;
          }
        }
      }

      .ant-picker-range {
        padding: 0;
        background: transparent;

        .ant-picker-input {
          input {
            border: 0 !important;
            min-width: 2.5em;
            text-align: center;
          }
        }

        .ant-picker-range-separator {
          padding: 0;
        }

        .ant-picker-active-bar {
          display: none;
        }

        .ant-picker-suffix {
          display: none;
        }

        .ant-picker-clear {
          opacity: 1;
          position: relative;
          transform: unset;
          inset-inline-end: unset;

          svg {
            width: 14px;
            height: 14px;
            color: #949699;
          }
        }

        &:hover {
          background: transparent !important;
        }
      }

      .search-divider {
        width: 2px;
        height: 2.5em;
        background: #7f7f7f;

        @media screen and (max-width: 550px) {
          width: 100%;
          height: 1px;
          background: #7f7f7f;
        }
      }

      input {
        border: 0 !important;
        outline: 0;
        background: transparent;
        min-width: 12em;
        font-size: 14px !important;
        font-family: "arial";
        color: var(--secondary_font_color);

        @media screen and (max-width: 660px) {
          min-width: unset !important;
        }

        &::placeholder {
          color: #808080 !important;
        }
      }

      button {
        display: flex;
        align-items: end;
        gap: 0.35em;
        width: fit-content;
        padding: 0.45em 1em;
        border-radius: 20px;
        background: #131417;
        transition: all 0.5s ease;

        @media screen and (max-width: 660px) {
          padding: 0.45em 0.6em;
        }

        svg {
          width: 20px;
          height: 20px;
          color: var(--secondary_font_color);
          margin-bottom: 0.08em;
        }

        p {
          margin: 0;
          font-size: 14px;
          font-family: "montserrat-medium";

          @media screen and (max-width: 660px) {
            display: none;
          }
        }

        &:hover {
          background: var(--secondary_button_color);
        }
      }
    }

    .order-list {
      padding: 15px 30px 5px;

      @media screen and (max-width: 991px) {
        padding: 15px 15px 5px;
      }
    }
    .order-item {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .info-list {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 0 10px;
    }

    svg {
      width: 19px;
      height: 19px;
      color: var(--primary_font_color);
    }
  }

  .order-grid {
    display: grid;
    align-items: center;
    margin-top: 10px;
    padding-left: 1em;
    grid-template-columns: 17% 25% 20% 20%;
    gap: 1.5em;

    @media (max-width: 991px) {
      grid-template-columns: 22% 30% 20% 20%;
    }

    @media (max-width: 600px) {
      padding-left: 0;
      align-items: end;
      grid-template-columns: repeat(4, 1fr);
    }

    h6 {
      width: 100%;
      margin-bottom: 0.25em;
      font-size: 14px;

      @media (max-width: 991px) {
        font-size: 13px;
      }
    }

    span {
      font-size: 13px;
      text-transform: capitalize;
      color: #dcdcdc;

      @media (max-width: 991px) {
        font-size: 12px;
      }
    }
  }

  .customer-grid {
    border: 0;
    padding: 1.5em 2em !important;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
    border-radius: 10px;
    background: #ffffff4a;

    @media (max-width: 600px) {
      gap: 6px;
    }

    h6 {
      margin-bottom: 0;
      color: var(--secondary_font_color);
      font-size: 13px;
      font-family: "montserrat-medium";

      @media (max-width: 991px) {
        font-size: 12px;
      }
    }

    span {
      margin-bottom: 0;
      color: var(--secondary_font_color);
      font-size: 14px;
      font-family: "montserrat-semibold";

      @media (max-width: 991px) {
        font-size: 13px;
      }
    }
  }

  .order-step {
    width: 100%;
    display: grid;
    margin-bottom: 1em;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 5em;
    }

    &.--pending {
      .step-layout:nth-child(1) {
        .step-circle {
          box-shadow: 0 0 0 5px #5b2e2b;
        }

        h6 {
          color: #ef442d;
          font-family: "montserrat-medium";
        }
      }

      .step-layout:nth-child(2),
      .step-layout:nth-child(3) {
        .step-circle {
          box-shadow: 0 0 0 2px #373737;
          background: #131417;

          svg {
            color: #4f4f4f;
          }
        }

        h6 {
          color: #4f4f4f;
        }
      }
    }

    &.--processing {
      .step-layout:nth-child(2) {
        .step-circle {
          box-shadow: 0 0 0 5px #5b2e2b;
        }

        h6 {
          color: #ef442d;
          font-family: "montserrat-medium";
        }
      }

      .step-layout:nth-child(3) {
        .step-circle {
          box-shadow: 0 0 0 2px #373737;
          background: #131417;

          svg {
            color: #4f4f4f;
          }
        }

        h6 {
          color: #4f4f4f;
        }
      }
    }

    .step-line {
      top: 10%;
      left: 60%;
      width: 80% !important;
      opacity: 1;
      border-width: 2px;
      position: absolute !important;
      border-color: #373737;

      @media (max-width: 600px) {
        background: #373737;
        height: 100%;
        width: 2px !important;
        left: 1.5em;
        top: 3.2em;
        border: 0;

        &.--active {
          background: #7d1216;
        }
      }

      &.--active {
        border-color: #7d1216;
      }
    }

    .step {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1em;

      @media (max-width: 600px) {
        flex-direction: row;
        justify-content: start;
        align-items: start;
      }

      .step-circle {
        z-index: 1;
        width: 35px;
        height: 35px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--secondary_button_color);

        svg {
          width: 22px;
          height: 22px;
          color: var(--primary_background_color);

          @media (max-width: 600px) {
            width: 20px;
            height: 20px;

            &.--wrench {
              width: 19px;
              height: 19px;
            }
          }

          &.--wrench {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    // @media (max-width: 600px) {
    //     gap: 6px;
    // }

    h6 {
      text-transform: capitalize;
      font-size: 13px;
      letter-spacing: 0.02em;
      color: var(--primary_font_color);
      font-family: "montserrat-semibold";

      // @media (max-width: 991px) {
      //     font-size: 12px;
      // }
    }

    span {
      text-transform: capitalize;
      font-size: 14px;
      color: #dcdcdc;
      font-family: "montserrat";

      @media (max-width: 991px) {
        font-size: 13px;
      }
    }
  }

  .order-frame {
    width: 100px;
    height: 90px;

    @media (max-width: 600px) {
      width: 80px;
      height: 70px;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .order-status {
    padding: 0.3em 1.5em 0.5em;
    border-radius: 20px;

    p {
      font-size: 12px;
      text-transform: capitalize;
      font-family: var(--secondary_font);
    }

    &.--completed {
      background: #76ffb42e;

      p {
        color: #5fe99d;
      }
    }

    &.--processing {
      background: #63daff2e;

      p {
        color: #63daff;
      }
    }

    &.--cancelled {
      background: #ff3d3d2e;

      p {
        color: #ff3d3d;
      }
    }

    &.--pending {
      background: #fff23e2e;

      p {
        color: #fff23e;
      }
    }

    &.--appointment {
      background: #ffab6d2e;

      p {
        color: #ff7c00;
      }
    }

    &.--paid {
      background: #7f68ff4d;

      p {
        color: #939bff;
      }
    }
  }
}

.service-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}

.car-ppf-page {
  .warranty-coverage {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    max-width: 1512px;
    background: var(--secondary_background_color);
    padding: 40px 0;
    gap: 50px;

    @media screen and (max-width: 991px) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }

    h3,
    h6,
    p {
      margin-bottom: 0;
    }

    h3 {
      font-size: 36px;
      font-family: var(--secondary_font);
      color: var(--primary_font_color);
      line-height: 36px;

      @media screen and (max-width: 991px) {
        font-size: 28px;
      }
    }

    p {
      font-size: 16px;
      font-family: "arial";
      color: #dcdcdc;
      line-height: 25px;

      @media screen and (max-width: 991px) {
        font-size: 15px;
      }

      &.--grey {
        font-family: var(--secondary_font);
        color: #848789;
        line-height: 19.5px;
      }
    }

    .warranty-text {
      display: flex;
      flex-direction: column;
      gap: 18px;
    }

    .warranty-list {
      width: 100%;
      max-width: 1068px;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 770px) {
        column-gap: 50px;
        row-gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
      }

      .warranty-item {
        display: flex;
        flex-direction: column;
        gap: 8px;

        h6 {
          font-size: 18px;
          font-family: "arial-bold";
          color: var(--secondary_font_color);
          line-height: 36px;

          @media screen and (max-width: 991px) {
            font-size: 16px;
          }

          @media screen and (max-width: 550px) {
            font-size: 15px;
          }
        }
      }

      .warranty-frame {
        width: 100px;
        height: 100px;
        background: #f3f6f8;
        border-radius: 100%;
        box-shadow: 10px 11px 17px 0px #0000002b;

        @media screen and (max-width: 991px) {
          width: 80px;
          height: 80px;
        }

        @media screen and (max-width: 550px) {
          width: 60px;
          height: 60px;
        }
      }
    }
  }

  .ppf-package {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1260px;

    .swiper {
      overflow: visible;
    }

    .package-list {
      width: 100%;
      // max-width: 1352px;
      display: flex;
      justify-content: center;
      gap: 25px;

      @media screen and (max-width: 1200px) {
        padding: 0 20px;
      }

      .package-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        width: 300px;
        height: 525px;
        cursor: pointer;

        @media screen and (max-width: 991px) {
          width: 280px;
          height: 500px;
        }

        &.--active {
          .package-detail {
            border-color: var(--primary_button_color);

            .package-frame {
              img {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
              }
            }
          }
        }
      }

      .package-detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        border-radius: 16px;
        padding-bottom: 15px;
        gap: 10px;
        background: var(--secondary_background_color);
        border: 4px solid #1e1f26;

        h6,
        p {
          margin-bottom: 0;
          color: var(--secondary_font_color);
        }

        h6 {
          text-align: center;
          font-size: 20px;
          font-family: "arial-bold";

          @media screen and (max-width: 991px) {
            font-size: 18px;
          }

          &.--price {
            font-style: italic;
          }
        }

        p {
          font-size: 14px;
          font-family: "arial";
          letter-spacing: 0.02em;

          @media screen and (max-width: 991px) {
            font-size: 13px;
          }

          &.--free {
            font-size: 11px;
            font-family: "arial";
            color: #848789;
          }
        }

        svg {
          width: 15px;
          height: 15px;
          color: #00c20a;
        }

        .feature-list {
          display: grid;
          gap: 5px;
          grid-template-columns: 55% 45%;
        }

        .free-list {
          display: flex;
          flex-direction: column;
          gap: 3px;
        }

        .package-text {
          display: flex;
          flex-direction: column;
          padding: 10px;
          gap: 10px;
        }

        .package-frame {
          width: 100%;
          height: 220px;

          img {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
          }
        }
      }
    }
  }

  .ppf-alacarte {
    display: grid;
    width: 100%;
    max-width: 1280px;
    gap: 4em;
    grid-template-columns: 40% 60%;
    place-items: center;

    @media screen and (max-width: 1200px) {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 991px) {
      grid-template-columns: 100%;
    }

    h5,
    p {
      color: var(--primary_font_color);
    }

    h5 {
      font-size: 22px;
      font-style: italic;
      font-family: "montserrat-bold";

      @media screen and (max-width: 991px) {
        font-size: 20px;
      }

      span {
        font-size: 14px;
        font-family: var(--primary_font);

        @media screen and (max-width: 991px) {
          font-size: 12px;
        }
      }
    }

    .alacarte-list {
      display: grid;
      gap: 15px;
      grid-template-columns: 48% 48%;

      @media screen and (max-width: 991px) {
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and (max-width: 780px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 580px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .alacarte-image {
      position: relative;
      padding-top: 6em;

      .car-frame {
        height: auto;
        max-width: 100%;
      }

      .accessories-frame {
        top: 0;
        left: 0;
        padding-top: 6em;
        position: absolute;
      }
    }
  }
}

.car-wrapping-page {
  .wrapping-service {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    max-width: 1512px;
    background: var(--secondary_background_color);
    padding: 40px 0;
    width: 100%;
    gap: 50px;

    h3,
    h6,
    p {
      margin-bottom: 0;
    }

    h3 {
      font-size: 36px;
      font-family: var(--secondary_font);
      color: var(--secondary_font_color);
      line-height: 36px;
    }

    p {
      font-size: 16px;
      font-family: "arial";
      color: #dcdcdc;
      line-height: 25px;

      @media screen and (max-width: 991px) {
        font-size: 15px;
      }

      &.--grey {
        font-family: var(--secondary_font);
        color: #848789;
        line-height: 19.5px;
      }

      &.--bottom {
        line-height: 40px;

        @media screen and (max-width: 991px) {
          line-height: 25px;
          width: 90%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .wrapping-text {
      display: flex;
      flex-direction: column;
      gap: 18px;

      @media screen and (max-width: 991px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .wrapping-list {
      width: 100%;
      max-width: 1068px;
      display: flex;
      gap: 20px;
      overflow: unset;

      @media screen and (max-width: 991px) {
        gap: 15px;
        width: 95%;
        margin: 0 auto;
        flex-wrap: wrap;
        justify-content: center;
      }

      .wrapping-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-radius: 16px;
        align-items: center;
        text-align: left;
        padding: 10px 15px;
        box-shadow: 5px 13px 28px 0px #00000036;
        background: #d5daff24;
        width: 198px;

        h6 {
          margin-bottom: 0.5em;
          font-size: 14px;
          font-family: "arial-medium";
          color: var(--primary_font_color);
          line-height: 17px;
        }

        p {
          font-size: 12px;
          font-family: "arial";
          color: #dcdcdc;
          line-height: 17px;
        }
      }

      .wrapping-frame {
        width: 90px;
        height: 90px;
        background: #f3f6f8;
        border-radius: 100%;

        @media screen and (max-width: 991px) {
          width: 70px;
          height: 70px;
        }
      }
    }
  }

  .bg-frame {
    width: 100%;
    height: 100%;
    position: absolute;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: brightness(0.3);
    }
  }

  .why-professional {
    margin: 50px 0 0 0;
    font-family: var(--secondary_font);
    font-size: 36px;
    text-align: center;
    color: #ffffff;
    line-height: 50px;
    z-index: 10;

    @media screen and (max-width: 991px) {
      width: 80%;
      // margin: 0 auto;
      font-size: 30px;
    }

    @media screen and (max-width: 550px) {
      font-size: 28px;
      line-height: normal;
    }
  }

  .wrapping-compare {
    display: flex;
    justify-content: center;
    gap: 32px;
    max-width: 680px;
    width: 100%;
    z-index: 10;
    margin: 0 0 50px 0;

    @media screen and (max-width: 991px) {
      width: 95%;
      gap: 15px;
      // margin: 0 auto;
    }

    .compare-item {
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-radius: 15px;
      padding: 10px;
      height: 100%;
      background: #dcdcdc;
      text-align: center;

      @media screen and (max-width: 991px) {
        border-radius: 15px;
      }

      &.--ours {
        background: var(--primary_button_color);

        h5 {
          color: #ededed;
        }

        p {
          font-family: "arial-bold";
          color: var(--secondary_font_color);
        }

        .compare-text {
          background: #ff6f6f;
        }

        .compare-frame {
          background: #f5f5f5;
        }
      }

      h5,
      p {
        margin-bottom: 0;
      }

      h5 {
        padding-top: 0.5em;
        font-size: 18px;
        font-family: "montserrat-semibold";
        color: #131417;
        line-height: 24px;

        @media screen and (max-width: 991px) {
          font-size: 16px;
        }
      }

      p {
        text-align: left;
        font-size: 14px;
        font-family: "arial";
        color: var(--primary_font_color);
        line-height: 20px;
      }

      .compare-frame {
        border-radius: 16px;
        background: #c0c0c0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .compare-text {
        padding: 10px;
        background: #797979;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;

        &.--min-height {
          min-height: 56px;

          @media screen and (max-width: 480px) {
            min-height: 80px;
          }
        }
      }
    }
  }
}

.car-coating-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .coating-description {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #080a0c !important;
    position: relative;

    @media screen and (max-width: 991px) {
      min-height: 700px;
    }

    .coating-frame {
      position: absolute;
      // filter: brightness(0.2);
      background: #080a0c !important;
      filter: hue-rotate(170deg) saturate(0.3) sepia(0.1) brightness(0.3);
    }

    .description-section {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      max-width: 1200px;
      // gap: 60px;
      border-top: 2px solid;
      border-bottom: 2px solid;
      // border-right: 2px solid #ffffff !important;
      border-image: linear-gradient(to right, transparent 30%, #ffffff75 80%)
        100% 1;
      border-bottom-right-radius: 60px;

      @media screen and (max-width: 1200px) {
        margin: 0 40px;
      }

      @media screen and (max-width: 991px) {
        border-top: 0 !important;
        border: 0;
        // border-image: unset !important;
        flex-wrap: wrap;
        // border-right: 2px solid #ffffff75;
        // border-bottom: 2px solid #ffffff75;
      }

      @media screen and (max-width: 550px) {
        margin: 0 20px;
      }

      &::after {
        @media screen and (min-width: 991px) {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 2px;
          height: 100%;
          background: #ffffff75;
        }
      }
    }

    .description-frame {
      min-width: 460px;
      height: 100%;
      border-top-left-radius: 60px;
      border-bottom-right-radius: 60px;
      box-shadow: 0 1px 40px #ffffff29;

      @media screen and (max-width: 991px) {
        min-width: unset;
        width: 100%;
        max-height: 300px;
        overflow: hidden;
      }

      img {
        border-top-left-radius: 60px;
        border-bottom-right-radius: 60px;
      }
    }

    .description-text {
      gap: 2em;
      display: flex;
      padding: 40px 60px;
      flex-direction: column;

      @media screen and (max-width: 1200px) {
        padding: 0px 30px;
      }

      @media screen and (max-width: 991px) {
        border-left: 2px solid #ffffff75;
        padding: 20px 30px;
      }

      h2,
      p {
        margin-bottom: 0;
        color: #ffffff;
      }

      h2 {
        font-size: 50px;
        font-family: "montserrat-semibold";

        @media screen and (max-width: 991px) {
          font-size: 40px;
        }

        @media screen and (max-width: 550px) {
          font-size: 30px;
        }
      }

      p {
        font-size: 16px;
        font-family: var(--primary_font);
      }
    }
  }

  .coating-package {
    width: 100%;
    display: flex;
    justify-content: center;

    .swiper {
      overflow: visible;
    }

    .package-list {
      width: 100%;
      max-width: 1068px;
      display: flex;
      justify-content: center;
      gap: 40px;

      @media screen and (max-width: 1050px) {
        padding: 0 20px;
      }

      .package-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        width: 330px;

        &.--active {
          .package-detail {
            border-color: var(--primary_button_color);

            .package-frame {
              img {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
              }
            }
          }
        }
      }

      .package-detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        border-radius: 16px;
        padding-bottom: 15px;
        gap: 15px;
        background: var(--secondary_background_color);
        border: 4px solid #1e1f26;

        h6,
        p {
          margin-bottom: 0;
          color: var(--primary_font_color);
        }

        h6 {
          text-align: center;
          font-size: 20px;
          font-family: "arial-bold";

          &.--price {
            font-style: italic;
          }
        }

        p {
          font-size: 14px;
          font-family: "arial";

          &.--value {
            font-size: 13px;
            font-family: "arial-bold";
            color: #ffffff;
            letter-spacing: -0.015em;
          }
        }

        span {
          font-family: "arial";
          font-size: 11px;
          color: #ff2727;
        }

        .feature-list {
          display: flex;
          flex-direction: column;
          gap: 15px;

          .feature-item {
            display: flex;
            justify-content: space-between;
          }

          svg {
            width: 15px;
            height: 15px;
            color: #b6b6b6;
          }
        }

        .package-text {
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding: 10px 20px 5px;
        }

        .package-frame {
          width: 100%;
          height: 100%;

          img {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
          }
        }
      }
    }
  }

  .coating-alacarte {
    display: grid;
    place-items: center;
    width: 100%;
    max-width: 950px;

    @media screen and (max-width: 1200px) {
      width: 95%;
      margin: 0 auto;
    }

    h5,
    p {
      margin-bottom: 0;
      color: var(--primary_font_color);
    }

    h5 {
      font-size: 22px;
      font-style: italic;
      font-family: "montserrat-bold";

      @media screen and (max-width: 991px) {
        font-size: 20px;
      }

      span {
        font-size: 14px;
        font-family: var(--primary_font);
      }
    }

    p {
      font-size: 18px;
      font-family: var(--secondary_font);

      @media screen and (max-width: 991px) {
        width: 220px;
        font-size: 14px;
      }

      @media screen and (max-width: 550px) {
        width: 180px;
        word-break: break-word;
        font-size: 14px;
      }
    }

    .alacarte-scroll {
      overflow-x: scroll;
      width: 100vw;

      .alacarte-section {
        padding-bottom: 20px;
        padding-right: 30px;
        max-width: 950px;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .alacarte-list {
      display: grid;
      align-items: center;
      gap: 30px;
      grid-template-columns: 3fr 1fr;

      @media screen and (max-width: 800px) {
        grid-template-columns: 1.75fr 1fr;
      }

      @media screen and (max-width: 550px) {
        grid-template-columns: 1fr 1fr;
      }

      // @media screen and (max-width: 400px) {
      //     grid-template-columns: 1fr 1.5fr;
      // }

      &.total {
        @media screen and (max-width: 991px) {
          display: flex;
          width: 95%;
          justify-content: center;
          gap: 0;
        }
      }
    }

    .alacarte-table-item {
      &:nth-child(1) {
        @media screen and (max-width: 991px) {
          width: 220px;
        }

        @media screen and (max-width: 550px) {
          width: 180px;
          word-break: break-word;
          font-size: 14px;
        }
      }
    }
  }
}

.car-tinted-page {
  .tinted-package {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 100px;

    @media screen and (max-width: 1300px) {
      width: 100%;
      // margin-left: auto;
      // margin-right: auto;
    }

    .package-first {
      display: flex;
      flex-direction: column;
      gap: 2em;
      margin-top: -5em;
    }

    h5,
    p {
      font-style: italic;
      margin-bottom: 0;
      color: var(--primary_font_color);
    }

    h5 {
      font-size: 24px;
      line-height: 35px;
      font-family: "montserrat-bold";

      @media screen and (max-width: 991px) {
        font-size: 20px;
      }
    }

    p {
      font-size: 18px;
      font-family: var(--secondary_font);

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }

      span {
        text-decoration: underline;
      }
    }
  }

  .download-series {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 9em 5em 10em;
    background: var(--secondary_background_color);
    margin-top: -9em;

    @media screen and (max-width: 991px) {
      padding: 9em 4em 10em;
    }

    &.--bottom {
      margin-top: -7em;
    }

    .download-list {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 40px;

      .swiper-wrapper {
        justify-content: center;

        @media screen and (max-width: 1200px) {
          justify-content: unset;
        }
      }
    }

    .download-item {
      width: 247px;

      @media screen and (max-width: 991px) {
        width: 207px;
      }
    }
  }

  .choose-type-vlt {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 770px) {
      margin: 0em 0 10em;
    }

    @media screen and (max-width: 550px) {
      margin: 22em 0 20em;
    }

    .car-frame {
      width: 35%;
      height: auto;

      @media screen and (max-width: 991px) {
        width: 45%;
      }

      @media screen and (max-width: 550px) {
        width: 70%;
      }
    }

    .choose-grid {
      top: 60%;
      left: 51%;
      width: 100%;
      z-index: 2;
      position: absolute;
      display: grid;
      row-gap: 20px;
      column-gap: 0px;
      grid-template-columns: 50% 50%;
      transform: translate(-48.5%, -50%);

      @media screen and (max-width: 770px) {
        top: 80%;
      }

      @media screen and (max-width: 550px) {
        top: 41.5%;
        width: 98%;
        row-gap: 45px;
      }
    }

    .section-grid {
      display: grid;

      &.--first {
        grid-template-columns: 70% 18%;

        @media screen and (max-width: 550px) {
          grid-template-columns: 70% 20%;
          row-gap: 85px;

          &:nth-child(3) {
            .section-form {
              align-items: flex-start !important;

              &.--first {
                h6,
                p {
                  text-align: start;
                }
              }
            }

            .section-image {
              &.--first {
                justify-content: end;
              }
            }
          }

          &:nth-child(4) {
            row-gap: 45px;

            .section-image {
              &.--first {
                margin-top: 4em;
                justify-content: start;
              }
            }
          }
        }
      }

      &.--last {
        margin-top: 8em;
        grid-template-columns: 20% 70%;

        @media screen and (max-width: 991px) {
          margin-top: 5em;
        }

        @media screen and (max-width: 550px) {
          row-gap: 50px;

          &:nth-child(1) {
            .section-image {
              &.--last {
                grid-column: span 2;
                justify-content: end;
              }
            }
          }

          &:nth-child(2) {
            .section-form {
              &.--last {
                h6 {
                  text-align: end;
                }

                p {
                  &.--italic {
                    text-align: end;
                  }
                }
              }
            }

            .section-image {
              &.--last {
                margin-top: 6em;
                grid-column: span 2;
                justify-content: start;
              }
            }
          }
        }
      }

      h6,
      p {
        margin-bottom: 0;
        color: var(--primary_font_color);
      }

      h6 {
        width: 100%;
        white-space: pre;
        font-size: 20px;
        font-family: "montserrat-bold";

        @media screen and (max-width: 550px) {
          font-size: 16px;
        }
      }

      p {
        font-size: 14px;
        font-family: var(--secondary_font);

        @media screen and (max-width: 550px) {
          font-size: 12px;
        }

        &.--italic {
          font-family: var(--primary_font);
          font-style: italic;
        }
      }

      .section-text {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .section-form {
        &.--first {
          display: flex;
          flex-direction: column;
          align-items: end;
          text-align: end;
          order: 1;
          padding-right: 2em;

          @media screen and (max-width: 991px) {
            padding-right: 2.5em;
          }

          @media screen and (max-width: 550px) {
            padding-right: 0em;
            grid-column: span 2;
            order: 2;
          }
        }

        &.--last {
          order: 2;
          padding-left: 0.5em;

          @media screen and (max-width: 991px) {
            padding-left: 1.5em;
          }

          @media screen and (max-width: 550px) {
            padding-left: 0em;
            grid-column: span 2;
            order: 1;
          }
        }
      }

      .section-image {
        width: 100%;

        &.--first {
          order: 1;
          display: grid;
          justify-content: center;
          align-items: start;

          @media screen and (max-width: 550px) {
            grid-column: span 2;
          }
        }

        &.--last {
          order: 2;
          display: grid;
          justify-content: end;
          align-items: start;
        }
      }

      .section-frame {
        width: 110px;
        height: 110px;
        border-radius: 100%;
        border: 5px solid #ffecdb;

        @media screen and (max-width: 991px) {
          width: 88px;
          height: 88px;
        }

        @media screen and (max-width: 770px) {
          width: 66px;
          height: 66px;
        }

        @media screen and (max-width: 550px) {
          width: 77px;
          height: 77px;
        }

        @media screen and (max-width: 400px) {
          width: 60px;
          height: 60px;
        }

        img {
          border-radius: 100%;
        }
      }
    }
  }
}

.accessories-page {
  .accessories-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1112px;
    gap: 16px;

    @media screen and (max-width: 991px) {
      width: 90%;
      margin: 0 auto;
    }

    h3 {
      font-size: 28px;
      color: var(--primary_font_color);
      font-family: var(--secondary_font);
    }

    p {
      font-size: 16px;
      margin-bottom: 0;
      color: var(--secondary_font_color);
    }

    .accessories-search {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 0 20px;
      margin: 0 50px;
      background: #57585a;

      @media screen and (max-width: 991px) {
        margin: 0;
        padding: 0 10px;
      }

      input[type="text"] {
        width: 100%;
        outline: 0;
        border: 0;
        border-radius: 0 !important;
        font-family: "arial";
        font-size: 15px;
        padding: 12px 15px;
        color: var(--secondary_font_color);
        letter-spacing: 0.02em;
        border-left: 1px solid #7f7f7f;
        background: transparent;

        &::placeholder {
          color: #808080;
        }
      }

      .clear-icon {
        padding: 0 5px;
        color: #a7a6a6;
        border-right: 1px solid #7f7f7f;
      }

      svg {
        width: 24px;
        height: 24px;
        margin-bottom: 0.1em;
      }
    }

    hr {
      opacity: 1;
      border-color: #353641;
      margin: 1.5em 50px;

      @media screen and (max-width: 991px) {
        margin: 1.5em 0;
      }
    }

    .accessories-category {
      margin: 0 50px;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 10px;

      @media screen and (max-width: 991px) {
        margin: 0;
        gap: 5px;
      }

      .category-item {
        padding: 7px 25px;
        border-radius: 6px;
        // border: 1px solid #dbdbdb;
        background: var(--secondary_background_color);

        @media screen and (max-width: 991px) {
          padding: 7px 8px;
        }

        p {
          font-family: "arial";
          font-size: 14px;
          color: var(--primary_font_color);
          letter-spacing: 0.02em;
        }

        &.--active {
          background: var(--secondary_button_color);

          p {
            color: #ffffff;
          }
        }
      }

      .show-category {
        min-height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.25em 0.8em 0 0.5em;

        p {
          font-size: 13px;
          font-family: "arial";
          color: #a1a1a1;
          border-bottom: 1px solid #a1a1a1;
          line-height: normal;
        }
      }
    }

    .accessories-filter {
      height: 100%;

      svg {
        width: 19px;
        height: 19px;
        margin-bottom: 0.1em;
        color: var(--secondary_font_color);

        &.--drop {
          width: 12px;
          height: 12px;
          margin-bottom: 0;
        }
      }

      p {
        font-size: 15px;
        margin-bottom: 0;
        font-family: "arial";
        letter-spacing: 0.02em;
        color: var(--secondary_font_color);
      }
    }
  }

  .accessories-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1112px;
    gap: 16px;

    @media screen and (max-width: 991px) {
      width: 90%;
      margin: 0 auto;
    }

    .body-wrap {
      margin: 0 50px;

      @media screen and (max-width: 991px) {
        margin: 0;
      }
    }

    h4 {
      font-family: var(--primary_font);
      color: var(--secondary_font_color);
      font-size: 22px;
      margin-bottom: 0;
      line-height: 33px;

      @media screen and (max-width: 991px) {
        font-size: 20px;
      }

      span {
        font-family: "montserrat-semibold";
      }
    }

    .accessories-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;

      @media screen and (max-width: 770px) {
        gap: 15px;
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and (max-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .accessories-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: var(--secondary_background_color);

        h6,
        p {
          margin-bottom: 0;
          color: var(--primary_font_color);
        }

        h6 {
          font-size: 18px;
          font-family: "arial-medium";
          min-height: 44px;
          line-height: 24px;

          display: -webkit-inline-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

          @media screen and (max-width: 991px) {
            font-size: 16px;
          }

          &.--price {
            font-family: "arial";
            font-size: 19px;
            letter-spacing: 0.02em;
            min-height: unset;

            @media screen and (max-width: 991px) {
              font-size: 17px;
            }

            span {
              font-size: 13px;
              font-family: "arial";
              color: #aaadb1;
              text-decoration: none;
              letter-spacing: 0em;
            }
          }
        }

        p {
          font-size: 14px;
          font-family: "arial";
          color: #666666;

          @media screen and (max-width: 991px) {
            font-size: 13px;
          }
        }

        .accessories-frame {
          height: 160px;
          // padding: 14px 12px;
          background: #fafafa;

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .accessories-text {
          display: flex;
          flex-direction: column;
          text-align: center;
          align-items: center;
          gap: 6px;
          padding: 10px 12px 12px;
        }
      }
    }
  }
}

.cart-page {
  display: grid;
  place-items: center;
  gap: 20px;

  @media screen and (max-width: 991px) {
    width: 90%;
    margin: 0 auto;
  }

  @media screen and (max-width: 550px) {
    width: 95%;
  }

  .cart-header,
  .cart-body {
    width: 100%;
    max-width: 1000px;
  }

  .cart-header,
  .form-section {
    padding: 12px 0 8px;
    border-radius: 10px;
    background: var(--secondary_background_color);
  }

  .cart-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .cart-header {
    h5,
    p {
      font-size: 14px;
      margin-bottom: 0;
      color: #2e3a59;
    }

    h5 {
      padding: 0 20px;
      font-size: 22px;
      font-family: var(--primary_font);
      color: var(--primary_font_color);
    }

    p {
      color: var(--secondary_font_color);
      font-family: var(--secondary_font);
      font-size: 14px;
    }

    svg {
      width: 22px;
      height: 22px;
      margin-right: 20px;
      margin-bottom: 0.2em;
      color: var(--secondary_font_color);
    }

    hr {
      opacity: 1;
      border-color: var(--primary_color);
      border-width: 3px;

      &.--grey {
        border-color: #f4f4f4;
        border-width: 2px;
      }
    }

    .header-list {
      display: grid;
      grid-template-columns: 38% 22% 18% 22%;
      text-align: center;

      @media screen and (max-width: 550px) {
        grid-template-columns: 40% 25% 40%;
      }
    }
  }

  .cart-body {
    h6 {
      &.--loading {
        font-family: "montserrat-semibold";
        font-size: 15px;
        margin-bottom: 0;
        color: #c4c3c3;

        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
    }

    p {
      width: 100%;
      color: var(--secondary_font_color) !important;
      font-family: "arial";
      font-size: 15px;
      margin-bottom: 0;

      @media (max-width: 800px) {
        text-align: end;
      }
    }

    .cart-subgrid {
      padding: 0 15px;
      margin: 1.5em 0 2.5em;
    }

    .cart-grid {
      display: grid;
      place-items: center;
      padding-left: 20px;
      grid-template-columns: 38% 20% 18% 24%;

      @media screen and (max-width: 550px) {
        padding-left: 15px;
        grid-template-columns: 40% 22% 18% 20%;
      }

      .cart-frame {
        width: 100px;
        height: 75px;

        @media screen and (max-width: 550px) {
          width: 90%;
          height: 60px;
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .image-col {
        display: flex;
        flex-direction: column;
        align-items: start;
      }

      .delete-icon {
        padding-left: 4px;
        // padding: 2px 5px 0;
        border-left: 1px solid #c4c4c4;

        svg {
          width: 21px;
          height: 21px;
          color: var(--secondary_button_color);

          @media screen and (max-width: 550px) {
            width: 17px;
            height: 17px;
          }
        }
      }

      .edit-icon {
        svg {
          width: 20px;
          height: 20px;
          color: var(--secondary_font_color);

          @media screen and (max-width: 550px) {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .cart-content {
      h6 {
        font-size: 14px;
        line-height: 18px;
        color: var(--primary_font_color);

        // display: -webkit-inline-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
        // overflow: hidden;
        // white-space: normal;
        // text-overflow: ellipsis;

        @media screen and (max-width: 550px) {
          font-size: 12px;
        }
      }

      p {
        margin-bottom: 0;
        font-family: "arial";
        font-size: 16px;
        color: #747474;

        @media screen and (max-width: 550px) {
          font-size: 14px;
        }

        &.--edit {
          font-size: 14px;
          color: #2e3a59;
          font-family: "montserrat-semibold";
        }

        &.--option {
          width: fit-content;
          text-align: start;
          font-size: 13px;
          color: #868693 !important;

          svg {
            width: 14px;
            height: 14px;
            color: #fb3318;
            margin: 0 0.7em 0em 0;
          }

          @media screen and (max-width: 800px) {
            font-size: 12px;

            svg {
              width: 13px;
              height: 13px;
            }
          }
        }
      }

      .remark-loading {
        svg {
          width: 25px;
          height: 25px;
          color: var(--secondary_font_color);

          @media screen and (max-width: 600px) {
            width: 18px;
            height: 18px;
          }
        }
      }

      .remark-input {
        width: 100%;
        border-bottom: 1px solid #ffffff !important;

        svg {
          width: 15px;
          height: 15px;
          color: #ffffff;

          @media screen and (max-width: 800px) {
            width: 14px;
            height: 14px;
          }
        }

        input {
          width: 100%;
          border: 0;
          outline: 0;
          background: transparent;
          font-size: 13px;
          font-family: "arial";
          color: #868693;

          @media screen and (max-width: 800px) {
            font-size: 12px;
          }
        }
      }
    }

    .form-list,
    .fyi {
      padding: 10px 25px;

      @media screen and (max-width: 991px) {
        padding: 10px 15px;
      }
    }

    .fyi {
      width: 60%;
      margin: 2em 0;

      @media screen and (max-width: 770px) {
        width: 100%;
      }
    }
  }
}

.about-us-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .about-justint {
    width: 100%;
    max-width: 1022px;
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 35px;

    @media screen and (max-width: 991px) {
      width: 95%;
      margin: 0 auto;
      gap: 20px;
    }

    @media screen and (max-width: 770px) {
      grid-template-columns: 1fr;

      .about-frame {
        &.--left {
          display: none;
        }
      }
    }

    .about-frame {
      &.--right {
        height: 260px;

        img {
          border-radius: 9px;
        }
      }
    }

    .about-card {
      display: flex;
      gap: 20px;
      flex-direction: column;
      padding: 20px 20px 35px;
      border-radius: 30px;
      background: var(--secondary_background_color);

      h4,
      p {
        margin-bottom: 0;
        color: var(--secondary_font_color);
      }

      h4 {
        font-size: 24px;
        font-family: "montserrat-semibold";
      }

      p {
        font-size: 16px;
        font-family: "arial";
        color: #dcdcdc;
      }
    }
  }

  .numbers {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--secondary_background_color);
    padding: 40px 0;

    @media screen and (max-width: 550px) {
      padding: 60px 0;
    }

    .numbers-grid {
      width: 100%;
      max-width: 1006px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 23px;

      @media screen and (max-width: 550px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 95%;
        margin: 0 auto;
      }
    }

    .numbers-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      padding: 0px 10px;
      text-align: center;

      h2,
      h6,
      p {
        margin-bottom: 0;
      }

      h2 {
        font-size: 48px;
        font-family: "arial-bold";
        background: linear-gradient(
          142.76deg,
          rgba(253, 33, 33, 0) -8.13%,
          #ff2121 74.24%
        );
        background-clip: text;
        color: transparent;

        @media screen and (max-width: 991px) {
          font-size: 40px;
        }
      }

      h6 {
        font-size: 20px;
        font-family: "montserrat-semibold";
        color: var(--primary_font_color);

        @media screen and (max-width: 991px) {
          font-size: 18px;
        }
      }

      p {
        font-size: 16px;
        font-family: var(--primary_font);
        color: #dcdcdc;

        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
  }

  .vision-mission {
    width: 100%;
    max-width: 1094px;
    display: flex;
    gap: 38px;

    @media screen and (max-width: 991px) {
      width: 95%;
      margin: 0 auto;
      gap: 20px;
    }

    @media screen and (max-width: 550px) {
      flex-direction: column;
    }

    h5,
    p {
      margin-bottom: 0;
    }

    h5 {
      color: var(--primary_font_color);
      font-size: 24px;
      font-family: "montserrat-bold";

      @media screen and (max-width: 991px) {
        font-size: 20px;
      }
    }

    p {
      color: #dcdcdc;
      font-family: "arial";
      font-size: 17px;
      line-height: 25px;

      span {
        font-family: "arial-bold";
      }

      @media screen and (max-width: 991px) {
        font-size: 15px;
        line-height: 24px;
      }
    }

    .vision-mission-text {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .vision-mission-item-grid {
      display: grid;
      border-radius: 9px;
      padding: 20px 0 5px 20px;
      grid-template-columns: 65% 35%;
      background: var(--secondary_background_color);

      @media screen and (max-width: 991px) {
        padding: 20px 0 20px 20px;
      }
    }
  }

  .our-location {
    margin-top: -50px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    background: linear-gradient(135deg, #d66a6a 0%, #480404 100%);

    @media screen and (max-width: 700px) {
      margin-top: -100px;
    }

    h4,
    p {
      margin-bottom: 0;
      color: #ffffff;
    }

    h4 {
      font-size: 30px;
      line-height: 1.8em;
      font-family: "montserrat-bold";

      @media screen and (max-width: 991px) {
        font-size: 28px;
      }
    }

    p {
      font-size: 16px;
      font-family: "montserrat";

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }
    }

    .curve-frame {
      width: 100%;

      @media screen and (max-width: 1300px) {
        height: 600px;
      }

      @media screen and (max-width: 700px) {
        height: 800px;
      }
    }

    .location-list {
      gap: 20px;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1272px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 1300px) {
        width: 90%;
        margin: 0 auto;
      }

      @media screen and (max-width: 991px) {
        width: 100%;
        padding: 0 0 0 3em;
      }

      @media screen and (max-width: 700px) {
        padding: 0 2em 0 2em;
      }

      .location-grid {
        gap: 24px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        overflow: unset;
        width: 100%;
      }

      .location-item-grid {
        gap: 24px;
        display: grid;
        border-radius: 16px;
        padding: 14px;
        grid-template-columns: 30% 65%;
        box-shadow: 0px 20px 27px 0px #0000000d;
        background: #ffffff;
        width: 600px;

        @media screen and (max-width: 991px) {
          max-height: 188px;
        }

        @media screen and (max-width: 700px) {
          max-height: 400px;
          display: flex;
          flex-direction: column;
          width: 350px;
        }
      }

      .location-frame {
        width: 100%;
        min-height: 160px;

        img {
          border-radius: 14px;
        }
      }

      .location-text {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h6,
        p {
          margin-bottom: 0;
        }

        h6 {
          font-size: 20px;
          color: #252f40;
          font-family: "arial-bold";

          @media screen and (max-width: 991px) {
            font-size: 18px;
          }
        }

        p {
          font-size: 16px;
          font-family: "arial";
          color: #67748e;

          &.--phone-no {
            font-family: "arial-bold";
            color: var(--tertiary_font_color);
          }

          @media screen and (max-width: 991px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .about-cta {
    width: 100%;
    display: grid;
    place-items: center;
    background: transparent;
    padding: 0 0 40px;

    @media screen and (max-width: 1300px) {
      width: 95%;
      margin: 0 auto;
    }

    h4,
    p {
      margin-bottom: 0;
      color: #ffffff;
    }

    h4 {
      font-size: 24px;
      font-family: "arial-bold";
      color: var(--primary_font_color);
    }

    p {
      font-size: 16px;
      font-family: "montserrat";
      color: #dcdcdc;
    }

    .subscribe-button {
      padding: 14px 34px !important;
      border-radius: 12px !important;
      background: linear-gradient(135deg, #fd8585 0%, #fb3318 100%);
      box-shadow: 0px 2px 4px 0px #00000034 !important;
    }

    .cta-text {
      width: 100%;
      max-width: 1272px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.claim-windscreen-page {
  h3,
  h6,
  p {
    margin-bottom: 0;
  }

  h3 {
    color: var(--primary_font_color);
    font-family: "montserrat-medium";
    font-size: 25px;

    @media (max-width: 991px) {
      font-size: 22px;
      line-height: 32px;
    }

    @media (max-width: 600px) {
      font-size: 20px;
      line-height: 30px;
    }

    span {
      color: #ff4931;
      font-family: "montserrat-semibold";
    }
  }

  h6 {
    color: #605e5e;
    font-family: "montserrat-medium";
    font-size: 15px;
    line-height: 22px;

    @media (max-width: 991px) {
      font-size: 14px;
    }

    @media (max-width: 600px) {
      font-size: 12px;
      display: -webkit-inline-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      white-space: normal;
      text-overflow: ellipsis;
    }
  }

  p {
    color: var(--primary_font_color);
    font-family: "montserrat";
    font-size: 17px;

    @media (max-width: 991px) {
      font-size: 16px;
    }

    @media (max-width: 600px) {
      font-size: 15px;
    }

    span {
      color: #ff4931;
      font-family: "montserrat-medium";
    }
  }

  .document-required {
    width: 70%;
    text-align: center;
    padding: 70px 100px;
    background: var(--secondary_background_color);

    @media (max-width: 1350px) {
      width: 90%;
    }

    @media (max-width: 991px) {
      padding: 70px;
    }

    @media (max-width: 600px) {
      width: 95%;
      padding: 50px 30px;
    }

    .car-type {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 4em;

      .type-item {
        cursor: pointer;
        width: 50%;
        padding: 15px 10px;
        border-bottom: 2px solid #605e5e;
        transition: all 0.5s ease;

        @media (max-width: 600px) {
          padding: 10px 5px;
        }

        &.--active {
          border-bottom: 2px solid #ff4931;

          h6 {
            color: #ff4931;
            font-family: "montserrat-semibold";
          }
        }
      }
    }

    .document-content {
      margin-top: 4em;
      display: flex;
      flex-direction: column;
      gap: 1em;
      text-align: left !important;

      li {
        color: var(--primary_font_color);
        font-family: "montserrat";
        font-size: 16px;

        @media (max-width: 991px) {
          font-size: 15px;
        }

        @media (max-width: 600px) {
          font-size: 14px;
        }
      }
    }
  }

  .what-is-windscreen {
    width: 85%;
    margin-top: 5em;
    position: relative;

    @media (max-width: 1350px) {
      width: 90%;
    }

    @media (max-width: 600px) {
      width: 95%;
    }

    .windscreen-coverage {
      z-index: 2;
      position: relative;
      width: 91%;
      margin-top: 2em;
      padding: 40px 90px;
      background: var(--secondary_background_color);

      @media (max-width: 991px) {
        padding: 40px 70px;
      }

      @media (max-width: 600px) {
        width: 100%;
        padding: 30px;
      }
    }

    .coverage-list {
      display: flex;
      flex-direction: column;
      gap: 1em;

      li {
        color: var(--primary_font_color);
        font-family: "montserrat";
        font-size: 17px;

        @media (max-width: 991px) {
          font-size: 16px;
        }

        @media (max-width: 600px) {
          font-size: 15px;
        }
      }
    }

    .coverage-bg {
      z-index: 1;
      position: absolute;
      width: 40%;
      top: -3%;
      right: 0;
      height: 88%;
      background: rgba(255, 255, 255, 0.24);

      @media (max-width: 991px) {
        width: 50%;
        height: 90%;
        right: -10%;
      }

      @media (max-width: 600px) {
        width: 50%;
        height: 90%;
        right: -5%;
      }
    }
  }

  .insurance-panel {
    width: 85%;
    margin-top: 5em;
    position: relative;

    @media (max-width: 600px) {
      width: 95%;
    }

    .insurance-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 8em;
      margin: 5em 0 10em;

      .insurance-frame {
        width: 130px;
        height: 80px;

        @media (max-width: 600px) {
          width: 100px;
          height: 50px;
        }
      }
    }
  }
}

.image-gallery {
  height: 100vh;
  width: 100%;
  background: #000000f1;
  overflow: hidden;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;

  .swiper-button-prev,
  .swiper-button-next {
    color: #f8f9fa;
    &::after {
      font-size: 35px;

      @media (max-width: 550px) {
        font-size: 25px;
      }
    }
  }

  .swiper-button-prev {
    @media (max-width: 550px) {
      left: -5px;
    }
  }

  .swiper-button-next {
    @media (max-width: 550px) {
      right: -5px;
    }
  }

  .swiper-slide {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    video,
    img {
      width: auto;
      height: auto;
      max-height: 90vh;
      max-width: 80%;
    }
  }

  .p-image-toolbar {
    display: flex;
    position: absolute;
    right: 8px;
    top: 8px;
    z-index: 100;
    padding: 1rem;
    gap: 2em;

    @media (max-width: 550px) {
      gap: 1em;

      svg {
        width: 22px !important;
        height: 22px !important;

        &.--download {
          width: 25px !important;
          height: 25px !important;
          margin-top: 0em;
          margin-left: -0.6em;
        }
      }
    }

    svg {
      width: 28px;
      height: 28px;

      &.--download {
        width: 33px;
        height: 33px;
        margin-top: -0.2em;
        margin-left: -0.8em;
      }
    }
  }
}

.login-modal {
  // padding: 20px 20px 25px;

  // @media (max-width: 550px) {
  //     padding: 18px 15px 25px;
  // }

  h2,
  h6,
  h4,
  p {
    margin: 0;
    color: var(--primary_font_color);
  }

  h2 {
    font-size: 30px;
    font-family: "montserrat-bold";

    @media (max-width: 991px) {
      font-size: 28px;
    }

    @media (max-width: 550px) {
      font-size: 25px;
    }
  }

  h6 {
    font-family: "montserrat-medium";
    font-size: 14px;

    @media (max-width: 550px) {
      font-size: 13px;
    }
  }

  h4 {
    font-family: "montserrat-semibold";
    font-size: 18px;
  }

  p {
    text-align: center;
    color: #b4b4b4;
    font-family: "arial";
    font-size: 14px;
    // font-family: 'montserrat-medium';

    span {
      text-decoration: underline;
    }
  }

  .login-header {
    background: #1e1f26;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 20px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .logo-frame {
      width: 130px;
      height: 90px;
    }

    p {
      padding: 0 25px;
    }

    .login-tab {
      margin-top: 1em;
      text-align: center;
      width: 100%;
      display: flex;

      .tab {
        width: 50%;
        padding: 8px 0;
        cursor: pointer;
        border-bottom: 2px solid #666666;

        p {
          font-size: 16px;
          color: #666666;
          font-family: "arial-medium";
        }

        &.--active {
          border-bottom: 2px solid #ff452c;

          p {
            color: #ff452c;
          }
        }
      }
    }
  }

  .login-body {
    padding: 40px 25px 50px;

    .sign-up {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .support-frame {
        width: 81px;
        height: 81px;
        margin-bottom: 1em;

        img {
          object-fit: none;
        }
      }
    }
  }

  .login-button {
    margin-top: 1em;
    padding: 10px 30px !important;
    background: var(--secondary_button_color) !important;

    &:disabled {
      background: var(--secondary_button_color) !important;
      cursor: not-allowed !important;
      opacity: 0.5 !important;

      p {
        opacity: 0.8;
      }
    }

    @media (max-width: 991px) {
      font-size: 14px !important;
    }
  }

  .login-form {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    gap: 18px;
    // padding: 0 1.5em;

    .login-input {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .phone-container {
        height: 40px;
        font-size: 15px;
        border-radius: 4px;
        padding: 0.4em 0.8em;
        // border: 1px solid #b7b4b4;
        background: #ffffff26;
        display: flex;
        align-items: center;
        gap: 0.3em;
        transition: border-color 0.3s ease;
        justify-content: space-between;

        &.--error {
          border: 2px solid #fb3318;
        }

        &:focus {
          border: 2px solid #fb3318;
        }

        p {
          margin: 0;
          font-size: 15px;
          font-family: "montserrat-medium";
          color: var(--primary_font_color);
        }

        input {
          border: 0;
          padding: 0;
          background: transparent;
          color: var(--primary_font_color);

          &:focus {
            border: 0;
          }
        }
      }

      .phone-wrap {
        padding: 0.4em 0.8em;
        display: flex;
        align-items: center;
        gap: 0.3em;
      }

      input {
        width: 100%;
        font-size: 15px;
        border-radius: 4px;
        border: 1px solid #b7b4b4;
        font-family: "montserrat-medium";
        padding: 0.4em 0.8em;
        outline: 0;
        transition: border-color 0.3s ease;

        &:focus {
          border: 2px solid #fb3318;
        }
      }
    }

    .send-otp-button {
      height: 100%;
      padding: 0 !important;
      border-radius: 6px;
      background: transparent !important;

      display: flex;
      align-items: center;

      p {
        // font-family: 'montserrat-bold' !important;
        margin: 0;
        white-space: pre !important;
        color: var(--primary_font_color) !important;
        font-size: 13px !important;
      }

      &:disabled {
        cursor: not-allowed !important;
        opacity: 0.5 !important;

        p {
          opacity: 0.8;
        }
      }
    }
  }
}

.edit-price-modal {
  padding: 3em;
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media screen and (max-width: 991px) {
    padding: 2em;
  }

  .edit-price {
    cursor: pointer;
    width: max-content;
    gap: 10px;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    border-radius: 14px;
    border: 1px solid #535353;
    background: #2b2b2c;
    box-shadow: 3px 1px 8px 0px #0000001a;

    h4 {
      margin-bottom: 0;
      font-size: 26px;
      font-family: "arial-bold";
      color: #ffffffe8;

      @media screen and (max-width: 991px) {
        font-size: 22px;
      }
    }

    svg {
      width: 22px;
      height: 22px;
      color: var(--secondary_button_color);

      @media screen and (max-width: 991px) {
        width: 20px;
        height: 20px;
      }
    }

    input {
      width: 3.5em;
      font-size: 26px;
      font-family: "arial-bold";
      line-height: normal;
      color: #ffffffe8;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid #535353;
      background: transparent;
      outline: 0;
      padding: 0 0.25em;
      border-radius: 0;

      @media screen and (max-width: 991px) {
        font-size: 22px;
      }
    }

    &.--focus {
      gap: 6px;
    }
  }

  .price-detail {
    padding: 20px 20px 10px;
    border-radius: 14px;
    background: #2b2b2c;
    box-shadow: 3px 1px 8px 0px #0000001a;

    @media screen and (max-width: 991px) {
      padding: 15px 15px 10px;
    }

    h6,
    p {
      margin: 0;
    }

    h6 {
      font-size: 15px;
      font-family: "arial-medium";
      color: #878787;

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }

      &.--title {
        font-size: 18px;
        font-family: "montserrat-semibold";
        color: var(--secondary_font_color);

        @media screen and (max-width: 991px) {
          font-size: 16px;
        }
      }
    }

    p {
      text-align: center;
      min-width: 20%;
      font-size: 15px;
      font-family: "montserrat-semibold";
      color: var(--secondary_font_color);

      @media screen and (max-width: 991px) {
        font-size: 13px;
      }

      &.--red {
        color: #f11010;
      }

      &.--blue {
        color: #b3a16a;
        // #5469f9;
      }
    }

    .price-row {
      display: flex;
      justify-content: space-between;
      padding: 2em 1em 1em;
      border-bottom: 1px solid #535353;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.confirm-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding: 4.5em 3em;

  @media screen and (max-width: 991px) {
    padding: 4em 2em;
  }

  p {
    margin: 0;
  }

  h3 {
    font-size: 18px;
    color: var(--primary_color);
    text-align: center;
    font-family: "montserrat-bold";
    margin-bottom: 0.8em;

    @media screen and (max-width: 550px) {
      font-size: 16px;
    }
  }

  p {
    font-size: 14px;
    color: var(--secondary_font_color);
    text-align: center;
    font-family: "montserrat";

    @media screen and (max-width: 550px) {
      font-size: 13px;
    }
  }

  .confirm-icon {
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff4b4b29;
    width: 8em;
    height: 8em;

    @media screen and (max-width: 550px) {
      width: 7em;
      height: 7em;

      svg {
        width: 3.5em !important;
        height: 3.5em !important;
      }
    }

    svg {
      width: 4em;
      height: 4em;
      color: var(--primary_color);
    }
  }

  .edit-price {
    cursor: pointer;
    width: max-content;
    gap: 10px;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    border-radius: 14px;
    border: 1px solid #535353;
    background: #2b2b2c;
    box-shadow: 3px 1px 8px 0px #0000001a;

    h4 {
      margin-bottom: 0;
      font-size: 26px;
      font-family: "arial-bold";
      color: #ffffffe8;

      @media screen and (max-width: 991px) {
        font-size: 22px;
      }
    }

    svg {
      width: 22px;
      height: 22px;
      color: var(--secondary_button_color);

      @media screen and (max-width: 991px) {
        width: 20px;
        height: 20px;
      }
    }

    input {
      width: 3.5em;
      font-size: 26px;
      font-family: "arial-bold";
      line-height: normal;
      color: #ffffffe8;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid #535353;
      background: transparent;
      outline: 0;
      padding: 0 0.25em;
      border-radius: 0;

      @media screen and (max-width: 991px) {
        font-size: 22px;
      }
    }

    &.--focus {
      gap: 6px;
    }
  }

  .price-detail {
    padding: 20px 20px 10px;
    border-radius: 14px;
    background: #2b2b2c;
    box-shadow: 3px 1px 8px 0px #0000001a;

    @media screen and (max-width: 991px) {
      padding: 15px 15px 10px;
    }

    h6,
    p {
      margin: 0;
    }

    h6 {
      font-size: 15px;
      font-family: "arial-medium";
      color: #878787;

      @media screen and (max-width: 991px) {
        font-size: 14px;
      }

      &.--title {
        font-size: 18px;
        font-family: "montserrat-semibold";
        color: var(--secondary_font_color);

        @media screen and (max-width: 991px) {
          font-size: 16px;
        }
      }
    }

    p {
      text-align: center;
      min-width: 20%;
      font-size: 15px;
      font-family: "montserrat-semibold";
      color: var(--secondary_font_color);

      @media screen and (max-width: 991px) {
        font-size: 13px;
      }

      &.--red {
        color: #f11010;
      }

      &.--blue {
        color: #b3a16a;
        // #5469f9;
      }
    }

    .price-row {
      display: flex;
      justify-content: space-between;
      padding: 2em 1em 1em;
      border-bottom: 1px solid #535353;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.accessories-modal {
  display: flex;
  padding: 0 0 20px;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 500px) {
    padding: 70px 0 200px;
  }

  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      background: #7b7b7b9c;
      border-radius: 100%;
      width: 30px;
      height: 31px;

      &::after {
        font-size: 11px;
        color: #ffffff;
        font-weight: 700;
        margin-right: 0.15em;
      }
    }

    .swiper-pagination {
      padding: 0.2em 1em;
      background: #7b7b7b9c;
      width: max-content;
      border-radius: 15px;
      font-size: 11px;
      left: 50%;
      transform: translateX(-50%);
      color: #ffffff;
      font-family: "montserrat-medium";
    }

    .swiper-button-next::after {
      margin-left: 0.15em !important;
    }
  }

  .accessories-frame {
    width: 100%;
    height: 270px;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .zoom-in {
      background: #2b2b2ce6;
      width: 35px;
      height: 35px;
      position: absolute;
      bottom: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      cursor: pointer;

      svg {
        color: #ffffff;
        width: 18px;
        height: 18px;
        margin-bottom: 0.1em;
        margin-right: 0.1em;
      }
    }
  }

  .accessories-name-price {
    padding: 10px 20px;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 10px;
    background: #57585a5e;
    box-shadow: 3px 1px 8px 0px #0000001a;

    @media screen and (max-width: 770px) {
      margin: 0 10px;
    }

    h6,
    h5 {
      margin: 0;
    }

    h6 {
      font-size: 18px;
      font-family: "arial";
      color: var(--secondary_font_color);
      line-height: 24px;
      min-height: 48px;
    }

    h5 {
      width: 100%;
      text-align: end;
      font-size: 22px;
      font-family: "arial-bold";
      color: #ff3840;
      line-height: 1em;

      @media screen and (max-width: 770px) {
        font-size: 20px;
      }
    }

    span {
      font-size: 13px;
      font-family: "arial";
      color: #aaadb1;
      text-decoration: none;
    }
  }

  .accessories-flex {
    display: flex;
    gap: 14px;
    margin: 0 20px;
    flex-direction: column;

    @media screen and (max-width: 770px) {
      margin: 0 10px;
    }

    h6,
    p {
      margin: 0;
    }

    h6 {
      min-width: 20%;
      font-size: 16px;
      font-family: "arial-medium";
      color: #878787;

      @media screen and (max-width: 991px) {
        font-size: 15px;
      }

      &.--title {
        min-width: unset;
        font-size: 17px;
        font-family: "montserrat-medium";
        color: var(--secondary_font_color);

        @media screen and (max-width: 991px) {
          font-size: 16px;
        }
      }
    }

    p {
      font-size: 14px;
      font-family: "montserrat";
      color: var(--secondary_font_color);
    }

    .desc {
      font-size: unset !important;
      font-family: "arial";
      letter-spacing: 0.02em;
      color: #535865;
    }

    hr {
      opacity: 1;
      border-color: #535353;
    }

    .accessories-detail {
      height: max-content;
      padding: 20px 20px 10px;
      border-radius: 14px;
      box-shadow: 3px 1px 8px 0px #0000001a;
      background: #2b2b2c;
      width: 100%;

      // @media screen and (min-width: 770px) {
      //     width: 50%;
      // }

      .product-row {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        padding: 2em 1em 1em;
        border-bottom: 1px solid #535353;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    .accessories-desc {
      overflow: hidden;
      padding: 20px 20px;
      border-radius: 14px;
      background: #2b2b2c;
      box-shadow: 3px 1px 8px 0px #0000001a;
      width: 100%;

      span,
      u,
      strong {
        color: var(--secondary_font_color) !important;
      }

      // @media screen and (min-width: 770px) {
      //     width: 50%;
      // }
    }
  }
}

.modal-footer {
  cursor: pointer;
  width: 100%;
  border-bottom-left-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
  position: sticky;
  bottom: 0;
  padding: 1.2em 0 0 0 !important;
  border: 0 !important;
  background-color: #1e1f26 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .footer-remark {
    width: 95%;
    border-radius: 0;
    padding: 0.5em 0.8em;
    background: transparent;
    outline: 0;
    box-shadow: unset !important;
    border-bottom: 1px solid #535353;
    color: #ffffff;
    font-size: 14px;
    display: flex;
    gap: 0.8em;
    align-items: center;
    margin: 0 0 1em 0 !important;
  }

  .footer-button {
    width: 100%;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
    background: var(--primary_color) !important;
    margin: 0 !important;
    padding: 15px 0 !important;
    text-align: center !important;

    &.--disabled {
      cursor: not-allowed;
      background: #f68d91 !important;
      // opacity: 0.5;

      h5 {
        opacity: 0.7;
      }
    }
  }

  .confirm-button {
    display: flex;
    gap: 3em;
    padding: 2em 2em;

    button {
      background-color: #2f3037 !important;
      padding: 11px 50px !important;

      &.--sure {
        background-color: var(--primary_color) !important;
      }
    }

    @media screen and (max-width: 550px) {
      // flex-wrap: wrap;
      width: 100%;
      gap: 2em;

      .element {
        &._button {
          width: 50% !important;
        }
      }

      button {
        padding: 11px 0 !important;
        width: 100% !important;
      }
    }
  }

  &.mobile {
    // width: 90%;
    // margin: 1em auto 0;
    // position: relative;
    // border-radius: 10px !important;
    position: fixed;
    // bottom: 10.5%;
    border-radius: 0 !important;

    .footer-button {
      border-radius: 0 !important;
    }
  }

  input {
    border: 0;
    outline: 0;
    width: 100%;
    background: transparent;
    color: #ffffff;
    font-size: 14px;
    font-family: "montserrat";

    @media screen and (max-width: 500px) {
      font-size: 13px;
    }
  }

  h5,
  h6 {
    margin: 0;
  }

  h6 {
    white-space: pre;
    font-size: 16px;
    font-family: "arial-medium";
    color: #878787;
    line-height: none;

    @media screen and (max-width: 500px) {
      font-size: 15px;
    }
  }

  h5 {
    width: 100%;
    color: #ffffff;
    text-transform: uppercase;
    font-family: "montserrat-semibold";
    letter-spacing: 0.03em;
    font-size: 19px;

    @media screen and (max-width: 991px) {
      font-size: 17px;
    }
  }
}

.fyi {
  padding: 10px 9px !important;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #f7c9101a;
  border: 1px solid #f7c910;

  p {
    margin-bottom: 0;
    line-height: 18px !important;
    font-family: "arial" !important;
    color: #dcdcdc !important;
    font-size: 12px !important;
    letter-spacing: 0.02em !important;
  }

  svg {
    width: 17px !important;
    height: 17px !important;
    color: #e9c328 !important;
  }
}

.common-text {
  display: flex;
  flex-direction: column;
  gap: 22px;
  max-width: 680px;
  padding-bottom: 6em;

  @media screen and (max-width: 1200px) {
    padding-bottom: 3em;
  }

  @media screen and (max-width: 550px) {
    gap: 18px;
  }

  &.text-black {
    h2 {
      color: #131417;
    }

    h6,
    p {
      color: var(--primary_font_color);

      @media screen and (max-width: 750px) {
        color: #131417;
      }
    }
  }

  &.--border {
    gap: 20px;

    h6 {
      display: flex;
      align-items: center;
      gap: 1em;
      color: #dcdcdc;
      font-size: 15px;
      font-style: italic;
      text-transform: uppercase;
      font-family: "montserrat";
      white-space: pre;

      span {
        display: block;
        width: 3.5em;
        height: 1px;
        background: #999999;
      }
    }

    h2 {
      color: var(--primary_font_color);
      font-family: "montserrat-semibold";
      font-style: unset;
    }

    p {
      color: #dcdcdc;
    }

    .with-border {
      margin-top: 20px;
    }
  }

  .with-border {
    gap: 18px;
    display: flex;
    align-items: end;

    .border {
      height: 80px;
      min-width: 10px;
      background: #fb3318;
      border: 0 !important;
      margin-bottom: 1em;

      @media screen and (max-width: 991px) {
        height: 70px;
      }

      @media screen and (max-width: 550px) {
        min-width: 8px;
        height: 55px;
        margin-bottom: 0.5em;
      }
    }
  }

  h2,
  h6,
  p {
    margin-bottom: 0;
  }

  h2 {
    text-transform: uppercase;
    font-family: "montserrat-bold";
    font-style: italic;
    color: var(--primary_font_color);
    font-size: 50px;

    @media screen and (max-width: 991px) {
      font-size: 40px;
    }

    @media screen and (max-width: 550px) {
      font-size: 30px;
    }
  }

  h6 {
    text-transform: uppercase;
    font-family: "montserrat";
    color: var(--secondary_font_color);
    font-size: 16px;

    @media screen and (max-width: 991px) {
      font-size: 14px;
    }

    @media screen and (max-width: 550px) {
      font-size: 13px;
    }
  }

  p {
    font-family: var(--primary_font);
    color: var(--secondary_font_color);
    font-size: 18px;

    @media screen and (max-width: 991px) {
      font-size: 15px;
    }

    @media screen and (max-width: 550px) {
      text-align: justify;
      font-size: 13px;
    }
  }
}

.common-lottie {
  width: 250px;
  height: 170px;
  margin-top: 8em;

  &.--empty {
    width: 170px;
    height: 135px;
    margin-top: 12.5em;
  }
}

.common-hero {
  width: 100vw;
  height: 100vh;
  margin-top: 0em;
  position: relative;
  overflow: hidden;

  &::after {
    position: absolute;
    bottom: -30em;
    left: 0;
    height: 300px;
    width: 100%;
    content: "";
    box-shadow: 0 -10px 120px #ffffff;
  }

  @media (max-width: 1350px) {
    margin-top: 8em;
    height: 800px;
  }

  @media (max-width: 991px) {
    height: 600px;
  }

  @media (max-width: 550px) {
    margin-top: 8em;

    img {
      object-position: 12%;
    }

    &.--about-us {
      img {
        object-position: center;
      }
    }
  }

  .hero-text {
    top: 41%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    align-items: center;
    text-align: center;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (max-width: 1350px) {
      top: 37%;
      gap: 25px;
    }

    @media (max-width: 991px) {
      gap: 22px;
    }

    @media (max-width: 750px) {
      top: 30%;
      gap: 20px;
    }

    // @media (max-width: 991px) {
    //     top: 52.5%;
    //     gap: 40px;
    // }

    h1 {
      font-family: "montserrat-bold";
      font-style: italic;
      color: #ffffff12;
      margin-bottom: 0;
      font-size: 280px;
      white-space: pre;

      @media (max-width: 1350px) {
        font-size: 250px;
      }

      @media (max-width: 1350px) {
        font-size: 200px;
      }

      @media (max-width: 700px) {
        top: 0;
        font-size: 180px;
      }
    }

    h2 {
      font-family: "montserrat-semibold";
      color: #ffffffe8;
      margin-bottom: 0;
      font-size: 70px;
      line-height: 85px;

      @media (max-width: 1350px) {
        font-size: 60px;
        line-height: 75px;
      }

      @media (max-width: 991px) {
        font-size: 40px;
        line-height: 55px;
      }

      @media (max-width: 750px) {
        font-size: 35px;
        line-height: 50px;
      }

      @media (max-width: 550px) {
        font-size: 30px;
        line-height: 45px;
      }
    }

    p {
      font-family: "montserrat-medium";
      // font-style: italic;
      color: #7b848f;
      margin-bottom: 0;
      font-size: 22px;
      padding: 0 8px;

      @media (max-width: 1350px) {
        font-size: 20px;
      }

      @media (max-width: 991px) {
        font-size: 15px;
        line-height: 28px;
      }

      @media (max-width: 550px) {
        font-size: 13px;
        line-height: 25px;
        font-family: "montserrat";
      }
    }

    hr {
      width: 16%;
      opacity: 1;
      border-width: 4px;
      border-color: var(--primary_color);
      margin: 0;

      @media (max-width: 550px) {
        width: 30%;
        border-width: 2px;
      }
    }

    .scrolling-text {
      width: 100% !important;
      position: absolute;
      top: 8%;

      .rfm-marquee-container {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .talk-button {
      letter-spacing: 0.05em;
      margin-top: 2em;
      background: var(--secondary_button_color);
      text-transform: none;
      font-size: 17px;
      padding: 13px 49px;
      z-index: 1;

      &:active {
        background: var(--secondary_button_color) !important;
      }

      &.about-us {
        margin-top: 0.25em !important;
      }

      @media (max-width: 1350px) {
        margin-top: 5em;
      }

      @media (max-width: 991px) {
        padding: 12px 36px 13px;
        font-size: 13px !important;
      }

      @media (max-width: 750px) {
        margin-top: 0em;
      }
    }
  }
}

.common-button {
  display: flex !important;
  align-items: center;
  gap: 4px;
  padding: 3px 15px !important;
  background: var(--secondary_button_color) !important;
  font-family: "arial" !important;
  letter-spacing: 0.02em !important;

  &.search {
    transform: unset !important;
    box-shadow: none !important;

    @media (max-width: 991px) {
      padding: 5px 0px !important;
      background: transparent !important;

      p {
        display: none;
      }

      svg {
        width: 21px !important;
        height: 21px !important;
        color: var(--secondary_button_color);
      }
    }
  }

  &.only-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    width: 24px;
    height: 23px;
    border-radius: 100% !important;
    box-shadow: unset !important;

    svg {
      width: 14px;
      height: 14px;
      margin-bottom: 0.25em !important;
    }
  }

  &.clear {
    color: #fb3318 !important;
    font-size: 13px !important;
    background: #fb331838 !important;
    box-shadow: none !important;
    padding: 5px 15px !important;
  }

  p {
    font-size: 14px !important;
    color: #ffffff !important;

    @media (max-width: 991px) {
      font-size: 12px !important;
    }
  }

  svg {
    width: 17px;
    height: 17px;
    // margin-top: 0.05em;

    @media (max-width: 991px) {
      width: 15px;
      height: 15px;
      margin-bottom: 0.08em;
    }
  }

  &.--without-icon {
    border-radius: 3px !important;
    padding: 5px 30px !important;
  }
}

.common-select {
  width: 100%;
  display: grid;
  place-items: center;

  .package-or-alacarte {
    overflow: hidden;
    border-radius: 12px;
    background: var(--secondary_background_color);
    display: flex;
    box-shadow: 0px 4px 4px 0px #00000040;

    @media screen and (max-width: 771px) {
      width: 70%;
    }

    @media screen and (max-width: 551px) {
      width: 90%;
    }

    .select-item {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      padding: 12px 30px 8px;
      background: transparent;
      min-width: 280px;

      @media screen and (max-width: 771px) {
        min-width: 50%;
      }

      // &.--brand {
      //     min-height: 78.8px;

      //     @media screen and (max-width: 550px) {
      //         padding: 12px 20px 8px;
      //     }
      // }

      h4,
      h6,
      p {
        font-style: italic;
        color: var(--primary_font_color);
        margin-bottom: 0;
      }

      h4 {
        font-size: 28px;
        font-family: "montserrat-semibold";
        line-height: 30px;
        text-align: center;
        white-space: pre;

        @media screen and (max-width: 991px) {
          font-size: 22px;
        }
      }

      p {
        font-size: 18px;
        font-family: var(--secondary_font);

        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
      }

      &.--active {
        background: var(--primary_button_color);

        h4,
        p {
          color: #ffffff;
        }
      }
    }
  }
}

.body-header {
  svg {
    width: 20px;
    height: 20px;
    color: var(--secondary_font_color);
    margin-bottom: 0.2em;

    &.--order {
      width: 22px;
      height: 22px;
    }
  }

  h6 {
    margin-bottom: 0;
    font-family: var(--secondary_font);
    color: var(--secondary_font_color);
    font-size: 14px;
  }
}

.total-price-row {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 30px;
  padding: 0 8%;
  margin-bottom: 2em;

  @media screen and (max-width: 550px) {
    flex-wrap: wrap;
    padding: 0 6% 0 0;
  }

  h6,
  p {
    margin-bottom: 0;
  }

  h6 {
    white-space: pre;
    font-family: "montserrat-bold";
    color: var(--secondary_font_color);
    font-size: 18px;
  }

  p {
    font-size: 14px !important;
    font-family: "arial";
    color: #b7b7b7 !important;
  }
}

.common-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
  backdrop-filter: blur(3px);
  background: #292929c4;
}

.download-file {
  background: #3a3b43;
  padding-left: 18px;
  display: flex;
  align-items: center;
  gap: 18px;
  color: #4f4f4f !important;

  &.series {
    width: 100%;
    justify-content: space-between;

    p {
      width: 100%;
      text-align: center;
    }
  }

  .download-icon {
    padding: 3px;
    background: #ffffff;
    border: 1px solid #ffffff;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  p {
    font-size: 14px !important;
    font-family: var(--primary_font) !important;
    color: #ffffff !important;
    margin-bottom: 0 !important;
  }
}

.common-package {
  width: 100%;
  display: grid;
  place-items: center;

  &.tinted {
    .package-detail {
      border-color: var(--primary_background_color);
    }
  }

  &.wrapping {
    .package-list {
      max-width: 790px;

      .swiper-wrapper {
        // justify-content: center;

        @media (max-width: 650px) {
          justify-content: unset;
        }
      }
    }
  }

  .package-list {
    gap: 25px;
    width: 100%;
    max-width: 1352px;
    display: flex;
    // justify-content: center;
    padding: 0 20px;

    .package-item {
      gap: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 230px;

      @media (max-width: 991px) {
        width: 180px;
      }

      &.--active {
        .package-detail {
          border: 4px solid var(--primary_button_color);

          h5,
          p {
            color: #f2f2f2;
          }

          .package-bg {
            background: linear-gradient(
              90deg,
              rgba(27, 3, 3, 0.6) 0%,
              rgba(129, 12, 12, 0.6) 100%
            );
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;

            &.--solid {
              min-height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: linear-gradient(
                90deg,
                rgba(27, 3, 3, 0.6) 0%,
                rgba(129, 12, 12, 0.6) 100%
              );
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              border-bottom-left-radius: 9px;
              border-bottom-right-radius: 9px;
            }
          }
        }
      }
    }

    .package-detail {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 16px;
      min-height: 254px;
      min-width: 230px;
      height: 100%;
      width: 100%;
      text-align: center;
      background-size: cover !important;
      background-position: center !important;
      border: 4px solid #1e1f26;

      @media (max-width: 991px) {
        min-width: 180px;
        min-height: 224px;
      }

      h5,
      p {
        text-transform: capitalize;
        margin-bottom: 0;
        color: var(--primary_font_color);
      }

      h5 {
        font-size: 24px;
        line-height: 32px;
        font-family: "arial-bold";

        &.--price {
          font-size: 21px;
          line-height: 29px;
          font-style: italic;

          @media (max-width: 991px) {
            font-size: 20px;
          }
        }

        @media (max-width: 991px) {
          font-size: 20px;
          line-height: 28px;
        }
      }

      p {
        font-size: 14px;
        font-family: "arial";

        @media (max-width: 991px) {
          font-size: 12px;
        }
      }

      .package-bg {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background: #22232bc4;
        padding: 10px 0;

        &.--solid {
          min-height: 50px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 9px;
          border-bottom-right-radius: 9px;
          background: #3a3b43;
          padding: 10px 0 4px;
        }
      }
    }
  }
}

.common-step {
  gap: 1.2em;
  display: flex;
  align-items: center;
  opacity: 0.6;

  &.--available {
    opacity: 1;

    h5 {
      color: var(--primary_font_color) !important;
    }

    p {
      color: #ff5d5d;
    }
  }

  h5,
  p {
    margin-bottom: 0;
  }

  h5 {
    font-size: 17px;
    font-family: "montserrat-medium";
    color: #9c9fb3;

    &.--no {
      font-size: 16px;
      color: #9c9fb3;
    }

    @media (max-width: 550px) {
      font-size: 15px;

      &.--no {
        font-size: 14px;
      }
    }
  }

  p {
    font-size: 13px !important;
    font-family: "montserrat";
    color: #9c9fb3;
  }

  .step-circle {
    position: relative;
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  .step-circle__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 33px;
    height: 33px;
    background-color: #1e1f26;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
  }

  .step-text {
    display: flex;
    flex-direction: column;
    align-items: start !important;
    justify-content: center;
    gap: 0.25em;
  }
}

.image-with-form {
  width: 100%;
  position: relative;

  .form-frame {
    width: 100%;
    height: 700px;
  }

  .form-section {
    top: 50%;
    position: absolute;
    padding: 30px 50px;
    border-radius: 15px;
    background: #ffffffc4;
    transform: translateY(-50%);
    right: 10%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    min-width: 580px;
    backdrop-filter: blur(5px);

    @media (max-width: 770px) {
      width: 95%;
      left: 50%;
      min-width: unset;
      padding: 30px 20px;
      transform: translate(-50%, -50%);
    }

    &.--claim-windscreen {
      left: 10%;
      right: unset !important;

      @media (max-width: 770px) {
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .formik-form {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    .file-upload {
      overflow: hidden;
      width: max-content;
      // padding: 8px 36px;
      border-radius: 5px;
      border: 1px solid #c7c7c7;
      box-shadow: 2px 2px 4px 0px #00000040;
      background: #ffffff;

      p {
        padding: 11px 38px 8px;
        color: #4b4d5f !important;
      }
    }

    #upload_file {
      cursor: pointer;
      position: absolute;
      opacity: 0 !important;
      height: 36px;
      width: 146px;
    }

    .submit-button {
      margin-top: 2em;
      padding: 12px 74px !important;
      font-size: 18px !important;
      font-family: "arial-medium";

      &:disabled {
        background: var(--primary_button_color) !important;
        opacity: 0.5 !important;
      }
    }

    h4,
    h6,
    p {
      margin-bottom: 0;
      font-family: "arial-bold";
    }

    h4 {
      text-align: center;
      font-size: 28px;
      color: #333333;

      @media (max-width: 991px) {
        font-size: 22px;
      }
    }

    h6 {
      font-size: 15px;
      color: #000000;
      font-family: "arial-medium";
    }

    p {
      font-size: 15px;
      font-family: "arial-medium";
      line-height: 1.4em;

      @media (max-width: 991px) {
        font-size: 14px;
      }

      &.--grey {
        margin-top: 0.2em;
        font-size: 13px;
        color: #4b4d5f;
        font-family: "arial";
        max-width: 300px;
      }
    }
  }

  .step-section {
    display: grid;
    align-items: center;
    height: 40px;
    position: relative;

    .step-line {
      opacity: 1;
      border-width: 2px;
      border-color: #76778259;
      margin: 0 35px;
    }

    .step {
      top: 0;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;

      h6,
      p {
        font-family: "arial-medium";
        margin-bottom: 0;
      }

      h6 {
        font-size: 20px;
        color: #ffffff;
        line-height: 2em;

        @media (max-width: 991px) {
          font-size: 18px;
        }
      }

      p {
        font-size: 16px;
        color: #000000;

        @media (max-width: 991px) {
          font-size: 15px;
        }
      }

      .step-bg {
        display: grid;
        place-items: center;
        width: 45px;
        height: 45px;
        border-radius: 100%;
        background: var(--secondary_button_color);

        @media (max-width: 991px) {
          width: 35px;
          height: 35px;
        }
      }

      &.--arrange {
        left: 0;
      }

      &.--claim {
        left: 50%;
        transform: translateX(-50%);
      }

      &.--done {
        right: 0;
      }
    }
  }

  .enquiry-message {
    margin-top: 0.8em;
    text-align: center;
    font-size: 13px !important;
    font-family: "arial" !important;
    color: #4b4d5f;

    span {
      cursor: pointer;
      color: #fb3318;
      border-bottom: 1px solid #fb3318;
    }
  }
}

.alacarte-button {
  width: 100%;
  padding: 20px !important;
  background: #f3f6f8 !important;
  color: #282828 !important;
  font-size: 15px !important;
  text-transform: uppercase !important;
  font-family: "montserrat-semibold" !important;
  transition: all 0.5s;

  @media screen and (max-width: 991px) {
    font-size: 13px !important;
    padding: 18px !important;
  }

  &.p-skeleton {
    background: #3d3e47 !important;
  }

  &.--active {
    color: #ffffff !important;
    background: var(--primary_button_color) !important;
  }

  &.coating {
    text-transform: none !important;
    padding: 14px !important;
    font-size: 16px !important;
  }
}

.total-button {
  width: 100%;
  padding: 9px !important;

  @media screen and (max-width: 991px) {
    padding: 7px !important;
  }

  h5,
  p {
    color: #ffffff !important;
  }

  h5 {
    margin-bottom: 0.2em;
    font-size: 19px !important;
    font-style: italic;
    font-family: "montserrat-semibold" !important;

    @media screen and (max-width: 991px) {
      font-size: 18px !important;
    }
  }

  p {
    margin-bottom: 0;
    font-size: 12px !important;

    @media screen and (max-width: 991px) {
      font-size: 11px !important;
    }
  }

  .total-divider {
    height: 100%;
    width: 0.05em;
    background: #ffffff;
  }

  &.coating {
    padding: 14px !important;
  }

  &.tinted {
    margin-top: 1em;
    padding: 9px 60px !important;

    h5 {
      font-size: 28px !important;

      @media screen and (max-width: 991px) {
        font-size: 22px !important;
      }
    }

    p {
      font-size: 18px !important;

      @media screen and (max-width: 991px) {
        font-size: 14px !important;
      }
    }

    &:disabled {
      opacity: 1 !important;
      background: #faa0a0 !important;
    }
  }

  &.ppf {
    border: 3px solid #af5555;

    &:hover {
      border: 3px solid #af5555;
    }

    &:disabled {
      border: 3px solid var(--primary_button_color);
    }
  }

  &.ppf,
  &.view-price {
    margin-top: 1.5em;
    padding: 10px 40px !important;
    width: max-content !important;
    border-radius: 15px !important;
    max-height: 48px;

    gap: 1em;
    display: flex;
    align-items: center;

    h5 {
      margin: 0 !important;
    }

    p {
      letter-spacing: 0.02em;
      font-family: "arial";
      font-size: 15px !important;
    }
  }

  &.view-price {
    background: #d9d9d9 !important;

    h5,
    p {
      color: #131417 !important;
    }

    p {
      width: unset !important;
    }

    .total-divider {
      background: #000000;
    }

    &:hover {
      background: #d9d9d9c9 !important;
    }
  }

  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
    background: var(--primary_button_color) !important;
    // background: #bdbdbd !important;

    h5,
    p,
    .total-divider {
      opacity: 0.8;
    }
  }
}

.add-cart-button {
  color: #b3b3b3 !important;
  font-style: italic;
  font-size: 18px !important;
  font-family: "montserrat-semibold" !important;
  background: var(--secondary_background_color) !important;
  border: 3px solid #1a1b1f !important;
  border-radius: 29px !important ;
  padding: 7px 20px !important;
  letter-spacing: -0.015em;

  &.--active {
    color: var(--tertiary_font_color);
    border: 1px solid var(--tertiary_font_color);
  }

  &.ppf {
    color: #ffffff !important;
    border-color: #af5555 !important;
    background: var(--primary_button_color) !important;

    &:disabled {
      color: #ffffff !important;
      border-color: #af5555 !important;
      background: var(--primary_button_color) !important;
      opacity: 0.5 !important;
    }

    &.--none {
      color: transparent !important;
      border-color: transparent !important;
      background: transparent !important;
    }
  }

  &.package,
  &.coating {
    font-size: 16px !important;

    @media screen and (max-width: 991px) {
      font-size: 14px !important;
    }

    &.--selected {
      background: var(--primary_button_color) !important;
      border: 3px solid #af5555 !important;
      color: var(--primary_font_color) !important;

      // &:hover {
      //     color: #ffffff !important;
      //     border-color: #ffcbcb !important;
      //     background: var(--primary_button_color) !important;
      // }
    }

    &.--none {
      color: transparent !important;
      border-color: transparent !important;
      background: transparent !important;
    }
  }

  &.accessories {
    width: 100%;
    font-size: 15px !important;
    letter-spacing: 0.03em !important;
    padding: 5px 0 !important;
    color: #ffffff !important;
    font-style: unset !important;
    text-transform: uppercase !important;
    font-family: var(--secondary_font) !important;
    border: 0 !important;
    background: var(--primary_color) !important;
    border-radius: 0 !important;

    @media screen and (max-width: 991px) {
      font-size: 14px !important;
      letter-spacing: 0.01em !important;
    }
  }
}

.text-with-arrow {
  display: flex;
  align-items: center;
  gap: 1em;

  &.--white {
    p,
    svg {
      color: var(--primary_font_color) !important;
    }

    svg {
      margin: 0;
    }
  }

  p {
    text-transform: uppercase;
    font-family: "montserrat";
    font-size: 13px !important;

    @media screen and (max-width: 550px) {
      font-size: 12px !important;
    }
  }

  svg {
    width: 17px;
    height: 17px;
    color: var(--secondary_font_color);
    margin-top: 0.1em;

    @media screen and (max-width: 550px) {
      margin: 0;
    }
  }
}

.choose-body-type {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .body-type-list {
    display: flex;
    align-items: end;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: 991px) {
      display: grid;
      width: 95%;
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (max-width: 550px) {
      width: 100%;
    }

    &.tinted {
      @media screen and (max-width: 991px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media screen and (max-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
      }

      .body-type-item {
        &.--active {
          @media screen and (max-width: 550px) {
            &:nth-child(1),
            &:nth-child(3) {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-top-right-radius: 12px;
              border-bottom-right-radius: 12px;
            }

            &:nth-child(2),
            &:nth-child(4) {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
      }
    }

    &.wrapping {
      @media screen and (max-width: 991px) {
        display: grid;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        grid-template-columns: repeat(2, 1fr);
      }

      .body-type-item {
        &.--active {
          @media screen and (max-width: 550px) {
            &:nth-child(1) {
              border-top-left-radius: 12px;
              border-bottom-left-radius: 12px;
            }
          }
        }
      }
    }

    .body-type-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: end;
      padding: 10px 50px;
      min-width: 280px;
      background: transparent;
      transition: all 0.3s ease;
      height: fit-content;

      @media screen and (max-width: 991px) {
        padding: 10px 20px;
        // width: 33.5%;
        min-width: unset;
      }

      h4,
      h6,
      p {
        font-style: italic;
        color: var(--primary_font_color);
        margin-bottom: 0;
      }

      h4 {
        font-size: 28px;
        font-family: "montserrat-semibold";
        line-height: 34.13px;

        @media screen and (max-width: 991px) {
          font-size: 22px;
          line-height: 30px;
        }

        @media screen and (max-width: 800px) {
          font-size: 19px;
          line-height: 27px;
        }

        @media screen and (max-width: 550px) {
          font-size: 17px;
          line-height: 25px;
        }
      }

      p {
        font-size: 18px;
        font-family: var(--primary_font);

        @media screen and (max-width: 991px) {
          font-size: 14px;
        }

        @media screen and (max-width: 800px) {
          font-size: 13px;
        }

        @media screen and (max-width: 550px) {
          font-size: 11px;
          line-height: 18px;
        }
      }

      .body-type-frame {
        width: 101px;
        height: 50px;

        @media screen and (max-width: 550px) {
          width: 61px;
          height: 25px;
        }
      }

      &.--active {
        border-radius: 12px;
        background: var(--primary_button_color);

        @media screen and (max-width: 550px) {
          &:nth-child(1) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          &:nth-child(3) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }

        h4,
        p {
          color: #ffffff;
        }
      }
    }
  }
}

.choose-series {
  width: 100%;
  display: grid;
  place-items: center;

  .series-list {
    overflow: hidden;
    border-radius: 12px;
    background: var(--secondary_background_color);
    display: flex;
    box-shadow: 0px 4px 4px 0px #00000040;

    @media screen and (max-width: 771px) {
      width: 70%;
    }

    @media screen and (max-width: 551px) {
      width: 90%;
    }

    &.--brand {
      box-shadow: unset !important;

      .series-item {
        min-height: 78.8px;
        background-size: contain !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-color: #1e1f26 !important;

        @media screen and (max-width: 550px) {
          padding: 12px 20px 8px;
        }
      }
    }

    .series-item {
      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      padding: 12px 30px 8px;
      background: transparent;
      min-width: 280px;

      @media screen and (max-width: 771px) {
        min-width: 50%;
      }

      &.--series {
        @media screen and (max-width: 550px) {
          padding: 12px 15px 8px;
        }
      }

      &.--lx-series {
        @media screen and (max-width: 550px) {
          min-width: 35%;
        }
      }

      h4,
      h6,
      p {
        font-style: italic;
        color: var(--primary_font_color);
        margin-bottom: 0;
      }

      h4 {
        font-size: 28px;
        font-family: "montserrat-semibold";
        line-height: 30px;
        text-align: center;
        white-space: pre;

        @media screen and (max-width: 991px) {
          font-size: 22px;
        }
      }

      p {
        font-size: 18px;
        font-family: var(--secondary_font);

        @media screen and (max-width: 991px) {
          font-size: 14px;
        }
      }

      &.--active {
        background: var(--primary_button_color);

        h4,
        p {
          color: #ffffff;
        }
      }
    }
  }
}

.image-with-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  background: var(--secondary_background_color);

  .image-with-text-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1512px;
    padding: 135px 135px 50px;
  }

  .image-text {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 680px;
    padding-bottom: 6em;
  }

  // h5, p {
  //     margin-bottom: 0;
  // }

  // h5 {
  //     font-size: 24px;
  //     font-family: 'montserrat-bold';
  //     color: var(--primary_font_color);
  //     line-height: 36px;

  //     @media screen and (max-width: 550px) {
  //         font-size: 22x;
  //     }
  // }

  // p {
  //     font-size: 18px;
  //     font-family: var(--primary_font);
  //     color: var(--secondary_font_color);
  //     line-height: 30px;

  //     @media screen and (max-width: 550px) {
  //         font-size: 16px;
  //     }
  // }

  &.--home {
    margin-top: 90px;

    .image-with-text-section {
      gap: 30px;
      padding: 65px 135px 10px;

      @media screen and (max-width: 1400px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 70px 0 30px;
      }

      @media screen and (max-width: 750px) {
        padding: 115px 0 0px;
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }

      .image-text {
        @media screen and (max-width: 550px) {
          order: 2;
        }
      }

      .image-frame {
        width: 500px;
        height: 385px;
        margin-top: -20em;
        min-width: 300px;

        @media screen and (max-width: 1400px) {
          width: 100%;
          height: 100%;
          min-width: 450px;
        }

        @media screen and (max-width: 1200px) {
          margin-top: -5em;
        }

        @media screen and (max-width: 991px) {
          min-width: 350px;
        }

        @media screen and (max-width: 750px) {
          margin-top: -20em;
          width: 80%;
          height: 80%;
          order: 1;
        }
      }
    }
  }

  &.--car-tinted {
    .image-with-text-section {
      // flex-direction: row-reverse;
      padding: 40px 80px;
      gap: 60px;

      @media screen and (max-width: 1400px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 40px 0;
      }

      @media screen and (max-width: 750px) {
        flex-wrap: wrap;
      }

      .image-frame {
        min-width: 450px;
        height: 400px;

        @media screen and (max-width: 1400px) {
          width: 100%;
          height: 100%;
        }

        @media screen and (max-width: 750px) {
          min-width: unset;
          display: flex;
          justify-content: center;
          margin-top: -5em;

          img {
            max-width: 500px;
          }
        }
      }

      .image-text {
        padding: 40px 0;
        max-width: unset !important;

        @media screen and (max-width: 991px) {
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          padding: 0;
        }
      }
    }
  }

  &.--car-ppf {
    background: transparent;

    // @media screen and (max-width: 991px) {
    //     padding: 5em 0 5em 2em;
    // }

    .image-with-text-section {
      justify-content: left;
      padding: 40px 80px;
      gap: 60px;

      @media screen and (max-width: 1400px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 40px 0;
      }

      @media screen and (max-width: 750px) {
        flex-wrap: wrap;
      }

      .image-frame {
        min-width: 450px;
        height: 400px;

        @media screen and (max-width: 1400px) {
          width: 100%;
          height: 100%;
        }

        @media screen and (max-width: 750px) {
          min-width: unset;
          display: flex;
          justify-content: center;
          margin-top: -5em;

          img {
            max-width: 500px;
          }
        }
      }

      .image-text {
        padding: 0;
        max-width: unset !important;
      }
    }
  }

  // &.--car-coating-top, &.--car-coating-bottom {
  //     .image-with-text-section {
  //         justify-content: left;
  //         flex-direction: row-reverse;
  //         padding: 0px 80px 0px;
  //         gap: 60px;

  //         @media screen and (max-width: 991px) {
  //             gap: 40px;
  //             padding: 50px 0;
  //             flex-wrap: wrap;
  //             width: 90%;
  //             margin: 0 auto;
  //         }

  //         .image-frame {
  //             min-width: 346px;
  //             height: 450px;
  //         }

  //         .image-text {
  //             padding: 0;
  //             max-width: unset !important;

  //             @media screen and (max-width: 1200px) {
  //                 padding: 30px 0;
  //             }

  //             @media screen and (max-width: 991px) {
  //                 padding: 0;
  //             }
  //         }
  //     }
  // }

  &.--car-coating-bottom {
    .image-with-text-section {
      flex-direction: row-reverse;
      padding: 40px 80px;
      gap: 60px;

      @media screen and (max-width: 1400px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 40px 0;
      }

      @media screen and (max-width: 750px) {
        flex-wrap: wrap;
      }
    }

    .image-text {
      padding: 40px 0;
      max-width: unset !important;

      @media screen and (max-width: 991px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
      }
    }

    .image-frame {
      min-width: 450px;
      height: 400px;

      @media screen and (max-width: 1400px) {
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: 750px) {
        display: flex;
        justify-content: center;
        margin-top: -5em;
        min-width: unset;

        img {
          max-width: 500px !important;
        }
      }
    }
  }

  &.--about-us {
    margin-top: 120px;

    .image-with-text-section {
      padding: 60px 100px 60px;

      @media screen and (max-width: 1200px) {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding: 60px 0 60px;
        gap: 50px;
      }

      @media screen and (max-width: 770px) {
        flex-wrap: wrap;
        justify-content: end;
        padding: 0 0 60px;
      }

      .image-frame {
        position: relative;
        width: 346px;
        height: 450px;
        margin-top: -14em;
        min-width: 300px;
        order: 2;

        @media screen and (max-width: 991px) {
          margin-top: -12em;
          height: 400px;
        }

        @media screen and (max-width: 770px) {
          margin-top: -8em;
          order: 1;
        }
      }

      .image-text {
        padding: 0;
        max-width: 500px !important;

        @media screen and (max-width: 770px) {
          order: 2;
          width: 100%;
          max-width: unset !important;
        }
      }

      .common-text {
        padding: 0 !important;

        @media screen and (max-width: 770px) {
          order: 2;
        }
      }
    }

    .founder-text {
      bottom: -2em;
      left: -4em;
      position: absolute;
      width: max-content;
      background: #afafafc4;
      backdrop-filter: blur(3px);
      padding: 5px 35px;
      border-radius: 10px;
      text-align: center;

      @media screen and (max-width: 550px) {
        left: 50%;
        transform: translateX(-50%);
      }

      h6,
      p {
        margin-bottom: 0;
      }

      h6 {
        font-size: 18px;
        font-family: var(--secondary_font);
        color: var(--primary_font_color);
      }

      p {
        font-size: 16px;
        font-family: var(--primary_font);
        color: var(--secondary_font_color);
      }
    }
  }
}

.specialize-in {
  background: transparent !important;
  width: 100%;
  max-width: 900px;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;

  @media screen and (max-width: 991px) {
    gap: 50px;
  }

  h3,
  p {
    margin-bottom: 0;
    color: var(--secondary_font_color);
    font-family: var(--primary_font);
  }

  h3 {
    font-size: 30px;

    @media screen and (max-width: 991px) {
      font-size: 25px;
    }

    @media screen and (max-width: 550px) {
      font-size: 22px;
    }
  }

  p {
    font-size: 16px;

    @media screen and (max-width: 991px) {
      font-size: 15px;
    }

    @media screen and (max-width: 550px) {
      font-size: 13px;
    }
  }

  .service-list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
      justify-content: center;
    }

    @media screen and (max-width: 991px) {
      gap: 1em !important;
    }
  }

  .service-item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 1em 2em;
    min-width: 120px;

    border-radius: 16px;
    box-shadow: 3px 3px 4px 0px transparent;
    background: transparent;
    transition: all 0.3s;

    @media screen and (max-width: 991px) {
      gap: 6px;
      padding: 0.8em 1em;
      min-width: 105px;
    }

    svg {
      width: 35px;
      height: 35px;
      color: #d1d1d1;

      @media screen and (max-width: 991px) {
        width: 28px;
        height: 28px;
      }
    }

    &.--active {
      box-shadow: 3px 3px 4px 0px #ff1f1f4d;
      background: #eaeaea;

      p {
        color: #f10b0b;
        font-family: "montserrat-semibold";
      }

      svg {
        color: #f10b0b;
      }
    }
  }
}

.modal-header {
  height: 70px;
  width: 100%;
  z-index: 5;
  position: sticky;
  padding: 1.5em 3em !important;
  background-color: #1e1f26;
  box-shadow: 0px 4px 25px 5px #00000040;
  border: 0 !important;

  &.fixed {
    position: sticky;
    top: 0;

    @media screen and (max-width: 500px) {
      position: fixed;
    }
  }

  &.br-34 {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;

    h4 {
      margin-bottom: 0;
      font-size: 21px;
      font-family: "montserrat-semibold";

      @media screen and (max-width: 991px) {
        font-size: 19px;
      }
    }
  }

  svg {
    width: 22px;
    height: 22px;
  }

  h4 {
    text-align: center;
    margin-bottom: 0;
    font-family: var(--primary_font);
    font-size: 28px;
    color: var(--secondary_font_color);
  }
}

.side-navbar-container {
  margin: 0;
  padding: 0;
  width: 200px;
  background-color: #f0f0f0;
  position: fixed;
  height: 100%;
  overflow: auto;
  .logo-icon {
    width: 6em;
    height: 6em;
  }
  .row:first-child {
    margin-top: 5em;
    margin-left: 0;
    margin-right: 0;
  }
  .tab-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222a39;
    font-size: 2rem;
    margin-top: 0.5em;
    &.--active {
      width: 7em;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1.7rem;
      background: #ebebeb;
      color: #222a39;
      font-size: 2rem;
    }
    img {
      margin-right: 0.5em;
    }
    .navbar-label {
      width: 3.5em;
    }
  }
  .post-button {
    width: 140px;
    box-shadow: none !important;
    border-radius: 1.7rem !important;
    margin: 0.5em 0 0 0;
    background: #414141 !important;
  }
  .nav-icon {
    width: 25px;
    height: 25px;
  }
}

.bottom-navbar-container {
  background-color: #ffffff;
  padding: 2em 0 2em 0;
  border-top: 0.1em solid #afacac;
  .row {
    .col {
      display: grid;
      place-items: center;
      max-height: 60px;
    }
  }
  .nav-icon {
    height: 40px;
    width: 40px;
  }
}

.full-modal-box-container {
  display: block !important;
  opacity: 1 !important;
  background: #292929c4;
  backdrop-filter: blur(5px);

  .modal-body::-webkit-scrollbar {
    display: none !important;
  }

  .modal-dialog {
    height: 100dvh;
    margin: 5em auto 0 !important;
    display: flex;
    align-items: center;
    max-width: 450px;
    z-index: 9999 !important;

    &.width-500 {
      max-width: 100%;

      @media screen and (min-width: 500px) {
        height: 96vh;
        max-width: 500px;
        margin: 7em auto 0 !important;
      }

      .modal-content {
        overflow-y: scroll;

        &.br-34 {
          height: 100%;

          @media screen and (max-width: 500px) {
            width: 100% !important;
            border-radius: 0 !important;
          }
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .modal-content {
    --mdb-modal-box-shadow: unset !important;
    height: max-content;

    &.login-modal {
      border-radius: 15px;
      background: #121212;

      @media screen and (max-width: 991px) {
        width: 90%;
        margin: auto;
      }
    }

    &.br-34 {
      border-radius: 25px;
      background: #121212;

      @media screen and (max-width: 991px) {
        width: 90%;
        margin: auto;
      }
    }
  }

  .modal-body {
    padding: 0;
    height: 100%;
  }
}

.full-modal-box-container::-webkit-scrollbar {
  display: none !important;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8em 0 0;

  .pagination {
    width: max-content;
    // background: #57585a;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2em;
    padding: 1em 2em;
    // border-radius: 8px;
    font-size: 16px;
    color: #ffffff;

    @media screen and (max-width: 550px) {
      gap: 0.8em;
      flex-wrap: wrap;
      font-size: 14px;
      padding: 1em 0.8em;
    }

    svg {
      width: 11px;
      height: 11px;
      color: #ffffff;
      margin-bottom: 0.1em;

      @media screen and (max-width: 550px) {
        width: 9px;
        height: 9px;
      }
    }

    li:not(.next):not(.previous) {
      // background: #000000;
      padding: 0.4em 0.7em;
      width: 36px;
      height: 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      // background: #57585a;

      @media screen and (max-width: 550px) {
        width: 32px;
        height: 33px;
      }

      &.active {
        border-radius: 5px;
        color: #ff121b;
        background: #ed1c2424;
      }
    }

    .next,
    .previous {
      padding: 0.4em 0.75em;
      background: #57585a;
      border-radius: 5px;

      &.disabled {
        opacity: 0.2;
      }
    }

    .next {
      margin-left: 2em;

      @media screen and (max-width: 550px) {
        margin-left: 0.8em;
      }
    }

    .previous {
      margin-right: 2em;

      @media screen and (max-width: 550px) {
        margin-right: 0.8em;
      }
    }
  }
}

.EZDrawer__container {
  background: #1e1f26 !important;
}

.dateRangePicker .ant-picker-panel:nth-child(2) {
  display: none;
}

.dateRangePicker .ant-picker-panel:nth-child(1) button {
  visibility: visible !important;
}

.ant-picker-dropdown {
  .ant-picker-header-view {
    button {
      &:hover {
        color: #fb3318 !important;
      }
    }
  }

  .ant-picker-cell-inner::before {
    border-color: #fb3318 !important;
  }

  .ant-picker-cell-range-start,
  .ant-picker-cell-range-end {
    &::before {
      background: #fb33181f !important;
    }

    .ant-picker-cell-inner {
      background: #fb3318 !important;
    }
  }

  .ant-picker-cell-in-range.ant-picker-cell-in-view::before {
    background: #fb33181f !important;
  }
}

.drawer {
  width: 500px !important;
  padding-bottom: 5em;
  overflow-y: scroll;

  h5,
  p,
  svg {
    margin: 0;
    color: var(--primary_font_color);
  }

  h5 {
    font-family: "arial-bold";
    letter-spacing: 0.02em;
    font-size: 18px;
    margin-top: 0.25em;
  }

  p {
    &.--empty {
      color: #9b9b9b !important;
      font-size: 14px !important;
      font-family: var(--primary_font);
    }
  }

  span {
    color: var(--primary_color);
  }

  .apply-button {
    font-size: 14px !important;
    padding: 7px 14px !important;
    background: var(--secondary_button_color) !important;
  }

  .drawer-header {
    top: 0;
    position: sticky;
    z-index: 10;
    // box-shadow: 0 1px 5px #f1f1f1;
    background: #131417;
  }

  .filter-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    padding: 1em 1.5em 0;
    overflow-y: auto;
    margin-bottom: 1.5em;
  }

  .filter-tag {
    cursor: pointer;
    height: max-content;
    width: max-content;
    min-width: 90px;
    padding: 0.5em 1.5em;
    background: #f0f0f021;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.2em;

    p {
      font-size: 13px;
      font-family: "montserrat-semibold";
      color: var(--primary_color);
    }

    svg {
      margin-top: 0.2em;
      color: #dfdfdf !important;
      width: 18px !important;
      height: 18px !important;
    }
  }

  .clear-all {
    padding: 0 0.5em;

    p {
      font-size: 13px;
      color: var(--primary_font_color);
      font-family: "montserrat-bold";
    }

    hr {
      margin: 0.5em 0 0;
      border-color: #27282f;
    }
  }

  .accordion-item {
    button {
      background: #1e1f26 !important;
      padding: 2em 1.5em 1.5em;
      font-size: 15px !important;
      color: var(--primary_font_color);
      letter-spacing: 0.02em;
      font-family: "arial-bold";
    }
  }

  .accordion-body {
    display: flex;
    padding: 0rem 2.25em 2.25em !important;

    p {
      font-size: 15px;
      color: var(--primary_font_color);
    }

    &.--brand {
      flex-direction: column;
      gap: 1em;
      overflow-y: auto;
      height: 300px;
      margin-bottom: 1.5em;
    }

    &.--model {
      gap: 1em;
      flex-wrap: wrap;
      max-height: 200px;
      overflow-y: auto;
      margin-bottom: 1.5em;
      padding-bottom: 0 !important;

      .model-item {
        cursor: pointer;
        padding: 0.8em 1.8em;
        height: max-content;
        background: #f0f0f021;

        p {
          color: #c7c7c7;
        }

        &.--selected {
          // border: 1px solid #d7d7d7;
          background: #ff979721;

          p {
            color: #ff000a;
            font-family: var(--secondary_font);
          }
        }
      }
    }

    &.--year {
      flex-direction: column;
      gap: 2em;
    }

    &.--model-empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
    }
  }

  .accordion-flush {
    --mdb-accordion-flush-btn-box-shadow: unset !important;
    --mdb-accordion-flush-border-bottom: 1px solid #353641 !important;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: url("../assets/images/service/filter-dropdown.svg");
  }

  .accordion-button::after {
    background-image: url("../assets/images/service/filter-dropdown.svg");
  }

  .year-input {
    gap: 1em;
    display: flex;
    align-items: end;
    width: 100%;

    p {
      margin-bottom: 0.25em;
      font-size: 13px;
    }

    input {
      width: 100%;
      padding: 0.7em 1.8em;
      border: 1px solid #d7d7d7;
      outline: 0;
      font-size: 15px;
      color: var(--primary_font_color);
      background: transparent;
    }
  }

  .range-slider {
    height: 6px;
    border-radius: 2px;
  }

  .range-slider__range {
    background: var(--primary_color) !important;
  }

  .range-slider__thumb {
    width: 22px !important;
    height: 22px !important;
    background: #ffffff !important;
    border: 3px solid var(--primary_color) !important;
  }

  @media (max-width: 500px) {
    width: 100% !important;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.checkbox-wrapper {
  --borderColor: #c1c0c0;
  --borderWidth: 0.125em;
  --checkedColor: var(--primary_color);

  input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    vertical-align: middle;
    background: transparent;
    font-size: 1.3em;
    border-radius: 0.125em;
    display: inline-block;
    border: var(--borderWidth) solid var(--borderColor);
    width: 1.35em;
    height: 1.35em;
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      background: var(--checkedColor);
      width: calc(var(--borderWidth) * 2.5);
      height: var(--borderWidth);
      top: 52%;
      left: 22%;
      transform-origin: left center;
    }

    &::before {
      transform: rotate(45deg)
        translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2))
        scaleX(0);
      transition: transform 100ms ease-in 100ms;
    }

    &::after {
      width: calc(var(--borderWidth) * 5);
      transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2))
        scaleX(0);
      transform-origin: left center;
      transition: transform 100ms ease-in;
    }

    &:checked:before {
      transform: rotate(45deg)
        translate(
          calc(var(--borderWidth) / -1.5),
          calc(var(--borderWidth) / -1.5)
        )
        scaleX(1);
      transition: transform 100ms ease-in;
    }

    &:checked:after {
      width: calc(var(--borderWidth) * 6);
      transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2))
        scaleX(1);
      transition: transform 100ms ease-out 100ms;
    }
  }
}

//custom preloader
:root {
  --logo-linka-color: #808080;
  --logo-vypln-color: #808080;
  --anim-Logo-linka: 3.3s;
  --anim-opacity_Logo_vypln: 0.5s;
  --anim-opacity_Logo_vypln-delay: 1s;
}

.logo-anim-wrapper {
  position: relative;
  display: flex;
  width: 180px;
  height: 53px;
}

.logo-anim-wrapper svg {
  position: absolute;
}

#Logo_tvar {
  opacity: 1;
}

.logo-linka {
  fill: transparent;
  stroke: var(--logo-linka-color);
}

#Logo_vypln {
  opacity: 0;
}

#Logo_vypln path {
  fill: var(--logo-vypln-color);
}

.logo-linka {
  stroke-dasharray: 0, 250;
  stroke-dashoffset: 125;
  animation: anim-Logo-linka var(--anim-Logo-linka)
    cubic-bezier(0.19, 0.74, 0.86, 0.37) infinite;
}

@keyframes anim-Logo-linka {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    stroke-dasharray: 250, 0;
    stroke-dashoffset: 1250;
  }
}

#Logo_vypln {
  opacity: 0;
  // animation: anim-opacity_Logo_vypln var(--anim-opacity_Logo_vypln)
  //   cubic-bezier(0.99, 0.06, 0.88, 0.58) forwards;
  // animation-delay: var(--anim-opacity_Logo_vypln-delay);
}

#Logo_vypln.fade-in {
  animation: anim-opacity_Logo_vypln var(--anim-opacity_Logo_vypln)
    cubic-bezier(0.99, 0.06, 0.88, 0.58) forwards;
  animation-delay: var(--anim-opacity_Logo_vypln-delay);
}

@keyframes anim-opacity_Logo_vypln {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.preloader {
  z-index: 110 !important;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5em;

  position: fixed;
  top: 0;
  left: 0;
  background: #1e1f26;

  .logo-frame {
    height: 70px;
    width: 120px;
  }
}
