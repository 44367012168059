@import "./font";

*,
*::after,
*::before{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html{
    background-color: #1e1f26 !important;
    font-size: 62.5%; //1rem == 10px (rem depend on root font size)
}

body{
    background-color: #1e1f26 !important;
    font-family: "montserrat", Arial !important;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &::-webkit-scrollbar {
        display: none !important;
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: transparent;
        display: none !important;
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        display: none !important;
        width: 0;
        height: 0;
    }
}