@font-face {
    font-family: "montserrat-light";
    src: url(../../assets/fonts/Montserrat-Light.ttf);
    font-display: swap;
}

@font-face {
    font-family: "montserrat";
    src: url(../../assets/fonts/Montserrat-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: "montserrat-medium";
    src: url(../../assets/fonts/Montserrat-Medium.ttf);
    font-display: swap;
}

@font-face {
    font-family: "montserrat-semibold";
    src: url(../../assets/fonts/Montserrat-SemiBold.ttf);
    font-display: swap;
}

@font-face {
    font-family: "montserrat-bold";
    src: url(../../assets/fonts/Montserrat-Bold.ttf);
    font-display: swap;
}

@font-face {
    font-family: "arial-light";
    src: url(../../assets/fonts/Arial-Light.ttf);
    font-display: swap;
}

@font-face {
    font-family: "arial";
    src: url(../../assets/fonts/Arial-Regular.ttf);
    font-display: swap;
}

@font-face {
    font-family: "arial-medium";
    src: url(../../assets/fonts/Arial-Medium.ttf);
    font-display: swap;
}

@font-face {
    font-family: "arial-bold";
    src: url(../../assets/fonts/Arial-Bold.ttf);
    font-display: swap;
}
