:root {
    //theme color
    --primary_color: #ED1C24;
    // --secondary_color: #f4f4f4;
    
    //background color
    --primary_background_color: #f4f4f4;
    --secondary_background_color: #131417;
    //#ffffff;

    //overlay color
    --overlay-backdrop: #6a6868e0;

    //text color
    --primary_font_color: #ffffff;
    //#000000
    --secondary_font_color: #ffffffe8;
    --tertiary_font_color: #FB3318;

    //font family
    --primary_font: "montserrat";
    --secondary_font: "montserrat-medium";

    //button color
    --primary_button_color: #FF4B4B;
    --secondary_button_color: #FB3318;
}

// //modal color
// --primary_modal_color: #3C387F;
// --secondary_modal_color: #D8D7E5;
// --tertiary_modal_color: #ffffff;

