@import "../abstract/variables";

.element {
  &._button {
    position: relative;

    button {
      border-radius: 6px;
      padding: 11px 74px;
      color: #ffffff;
      font-size: 15px;
      font-family: var(--secondary_font);
      text-transform: capitalize;

      &:disabled {
        background-color: var(--secondary_background_color);
        opacity: 0.4;
      }

      &:hover {
        box-shadow: unset;
      }

      &:active {
        background-color: var(--primary_button_color);
      }
    }

    .solid-button {
      outline: none;
      background-color: var(--primary_button_color);
      box-shadow: unset;
    }

    .outline-button {
      border: 1px solid var(--tertiary_text_color);
      color: var(--tertiary_text_color);
      background-color: transparent !important;
      box-shadow: unset;
    }
  }
  &._input-count {
    display: flex;
    align-items: center;
    gap: 0.5em;

    @media screen and (max-width: 600px) {
      gap: 0;
    }

    svg {
      width: 20px;
      height: 20px;
      color: var(--secondary_font_color);

      &.--loading {
        width: 25px;
        height: 25px;
      }

      @media screen and (max-width: 600px) {
        width: 18px;
        height: 18px;
      }
    }

    input {
      font-size: 14px;
      text-align: center;
      width: auto;
      padding: 0em 0.5em;
      border: 0;
      outline: 0;
      background: transparent;
      color: var(--secondary_font_color);

      @media screen and (max-width: 600px) {
        font-size: 12px;
        padding: 0em;
      }
    }

    .quantity-button {
      &.--disabled {
        opacity: 0.7;
      }
    }
  }
  &._code-input {
    label {
      font-size: 14px;
      font-family: "poppins";
      color: var(--secondary_text_color);
      margin: 1em 0em 0em 0.1em;
      span {
        color: #ee5b5b;
      }
    }

    @media (max-width: 500px) {
      font-size: 12px;
    }

    div {
      height: 45px;
      gap: 2em;
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding: 0em 0em 0em 0em;
      color: var(--secondary_text_color) !important;

      input {
        font-size: 20px;
        text-align: center;
        padding: 0.4em 0.5em;
        border: 0;
        border-radius: 0;
        box-shadow: none !important;
        width: 100%;
        height: 100%;
        outline: 0;
        color: var(--secondary_font_color);
        border: 0 !important;
        background: #ffffff26 !important;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 60px var(--primary_background_color) inset !important;
        background-color: #fafafa !important;
        background-clip: content-box !important;
      }

      @media (max-width: 550px) {
        gap: 1em;

        input {
          font-size: 14px;
        }
      }
    }
  }
  &._text {
    font-size: 16px;
    font-family: "arial";
    outline: none !important;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      caret-color: #f8f8f8;
      -webkit-text-fill-color: #f8f8f8;
      -webkit-background-clip: text;
      transition: background-color 5000s ease-in-out 0s;
    }

    label {
      font-size: 14px;
      font-family: "arial";
      letter-spacing: 0.02em;
      color: var(--secondary_font_color);
      margin-left: 0.2em;

      &.--round-field {
        font-size: 13px;
        color: var(--secondary_font_color);
        font-family: "montserrat-medium";
      }

      span {
        color: #ee5b5b;
      }

      .message {
        font-size: 11px;
        font-style: italic;
        font-family: "arial";
        color: #8f929a !important;
      }

      @media (max-width: 550px) {
        font-size: 14px;
      }
    }

    .prefix {
      display: flex;
      align-items: center;
      gap: 0.3em;
      padding: 0.3em 0;
      // background-color: #f4f4f421;
      padding: 0.9em 1.5em;
      border-radius: 28px;

      input {
        border-radius: 0 !important;
        padding: 0 !important;
        background: transparent !important;

        &:focus {
          box-shadow: unset !important;
          // background: var(--primary_background_color) !important;
        }
      }

      label {
        margin-bottom: 0.1em;
        color: var(--primary_font_color);
        font-size: 14px;
        font-family: "arial";
        letter-spacing: 0.02em;
      }
    }

    .with-otp {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &.mdb-field {
      margin: 0 0rem 0.1rem;
      border-radius: 10px;
      border: 2px solid transparent !important;
      color: var(--secondary_text_color);
      background: #ffffff !important;
      box-shadow: 2px 2px 4px 0px #00000040;

      &.--date {
        .form-outline {
          .form-label {
            padding: 1.4em 0em 0em;
            margin-left: 0em;
          }
        }
      }

      .ant-picker {
        padding: 0;
        position: absolute;
        width: 92%;
        margin: 2.2em 0.8em 0.8em;
        border: 0;
        color: var(--secondary_text_color) !important;
        font-size: 14px;
        font-family: "arial";
        background: #f8f8f8;
        outline: 0 !important;
        box-shadow: unset !important;

        @media (max-width: 991px) {
          width: 90%;
        }
      }

      .form-outline {
        .form-label {
          background-color: transparent;
          padding: 1.45em 0em 0em;
          margin-left: 0.5em;
          line-height: 16px;

          label {
            font-size: 15px;
            font-family: "arial";
            color: #4b4d5f !important;

            @media (max-width: 550px) {
              font-size: 14px;
            }
          }

          @media (max-width: 550px) {
            margin-left: 0.25em;
          }
        }
        .form-control {
          min-height: 3em;
          padding: 1.5em 1em 0.25em;
          font-size: 16px;
          font-family: "arial-medium";
          color: #131417;
        }

        .form-control[readonly] {
          background-color: transparent;
        }

        .form-control:disabled {
          background-color: transparent !important;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        caret-color: var(--secondary_text_color);
        -webkit-text-fill-color: var(--secondary_text_color);
        -webkit-background-clip: text;
      }

      &.--disabled {
        opacity: 0.5;
        background-color: #f1f1f1;

        .form-outline {
          .form-label {
            background-color: #f1f1f1;
          }
        }
      }

      &.--error {
        border: 2px solid #ff5c5c6b;

        .form-outline {
          .form-label {
            color: #ff5c5c !important;
          }
          .form-control {
            color: #ff5c5c;
          }
        }
      }

      &.--autofill {
        background-color: #0c2945 !important;

        .form-outline {
          .form-label {
            color: #f8f8f8 !important;
          }
          .form-control {
            color: #f8f8f8 !important;
          }
        }
      }

      .form-notch {
        display: none !important;
      }
    }

    &.line-field {
      margin: 0.5rem 0;
      margin-bottom: 0.1rem;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: 1.5px solid var(--secondary_background_color);
      outline: none;

      input {
        background-color: transparent;
        padding: 1.2em 1em 0.5em;
        color: var(--secondary_text_color) !important;
        font-size: 16px;
        font-family: "poppins";
        line-height: 22px;
        border: none !important;
        outline: none !important;
        border-bottom: 1.5px solid var(--secondary_background_color);

        &::placeholder {
          color: var(
            --tertiary_text_color
          ) !important; // Change this to the desired color
        }

        @media (max-width: 550px) {
          font-size: 14px;
        }
      }

      .phone_no,
      .phone_no_otp {
        padding: 0.6em 0.5em 0.5em;
        letter-spacing: 0.05em;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        caret-color: var(--primary_modal_color);
        -webkit-text-fill-color: var(--primary_modal_color);
        -webkit-background-clip: text;
        box-shadow: 0 0 0 20px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
      }

      &.--black {
        border-bottom: 1.5px solid var(--secondary_text_color);

        input {
          color: var(--secondary_text_color) !important;
          // padding: 1.2em 1em;
          font-size: 16px;
          font-family: "cabin";
          border-bottom: 1.5px solid var(--secondary_text_color);

          &::placeholder {
            color: #c3c4cd !important;
          }

          @media (max-width: 550px) {
            font-size: 14px;
          }
        }
      }

      &.--primary {
        border-bottom: 1.5px solid var(--primary_modal_color);

        input {
          // padding: 1.2em 1em;
          font-size: 16px;
          font-family: "cabin";
          border-bottom: 1.5px solid var(--primary_modal_color);

          &::placeholder {
            color: #c3c4cd !important;
          }

          @media (max-width: 550px) {
            font-size: 14px;
          }
        }
      }

      &.--disabled {
        opacity: 0.7;
        background-color: #f1f1f1;

        .form-outline {
          .form-label {
            background-color: #f1f1f1;
          }
        }
      }

      &.--error {
        border: 2px solid #ff5c5c6b;

        .form-outline {
          .form-label {
            color: #ec5353 !important;
          }
          .form-control {
            color: #ec5353;
          }
        }
      }

      .form-notch {
        display: none !important;
      }
    }

    &.round-field {
      width: 25em;
      margin: 0.5rem 0;
      margin-bottom: 0.1rem;
      border-radius: 28px;
      border: 2px solid var(--secondary_modal_color);
      background-color: #f4f4f421 !important;

      @media screen and (max-width: 550px) {
        width: 100%;
      }

      input {
        width: 100%;
        background-color: transparent;
        padding: 0.9em 1.5em;
        color: var(--primary_font_color);
        font-size: 14px;
        font-family: "arial";
        border: none !important;
        outline: none !important;
        border-radius: 28px;
        letter-spacing: 0.02em;
        transition: all 0.2s;

        &:disabled {
          opacity: 0.5 !important;
          cursor: not-allowed;
          // background-color: var(--primary_background_color);
        }

        &::placeholder {
          font-family: "arial";
          color: rgb(126, 126, 126) !important;
        }

        &:focus {
          box-shadow: 0 0 0 2px rgba(76, 91, 103, 0.1);
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        caret-color: var(--secondary_font_color);
        -webkit-text-fill-color: var(--secondary_font_color);
        border: 0;
      }

      &.--autofill {
        border: 0 !important;

        input {
          opacity: 0.8 !important;
          background-color: #f4f4f421 !important;
          color: var(--secondary_text_color);
          border: 0 !important;
          // border: 1.5px solid #0c2945 !important;
          // color: #C1C1C1;
          // font-size: 14px;
          // font-family: 'poppins';

          // &::placeholder {
          //     color: #C1C1C1 !important;
          // }
        }
      }

      &.--grey {
        input {
          color: var(--secondary_text_color) !important;
          font-size: 14px;
          font-family: "poppins";

          &::placeholder {
            color: #cdcdcd !important;
          }
        }
      }

      &.--disabled {
        input {
          opacity: 0.4;

          .form-outline {
            .form-label {
              background-color: #f1f1f1;
            }
          }
        }
      }

      &.--error {
        border: 2px solid #ff5c5c6b;

        .form-outline {
          .form-label {
            color: #ff5c5c !important;
          }
          .form-control {
            color: #ff5c5c;
          }
        }
      }

      .form-notch {
        display: none !important;
      }
    }

    &.withdraw-field {
      margin: 0.5rem 0;

      input {
        width: 4em;
        color: var(--primary_modal_color);
        font-size: 38px;
        font-family: "poppins-semibold";
        outline: none !important;
        border: none !important;
        background-color: transparent !important;

        &::placeholder {
          color: var(--tertiary_text_color) !important;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        caret-color: #f8f8f8;
        -webkit-text-fill-color: #f8f8f8;
        -webkit-background-clip: text;
        transition: background-color 5000s ease-in-out 0s;
        border: 1.5px solid var(--primary_background_color) !important;
      }

      &.--disabled {
        input {
          opacity: 0.4;

          .form-outline {
            .form-label {
              background-color: #f1f1f1;
            }
          }
        }
      }

      &.--error {
        border: 2px solid #ff5c5c6b;

        .form-outline {
          .form-label {
            color: #ff5c5c !important;
          }
          .form-control {
            color: #ff5c5c;
          }
        }
      }

      .form-notch {
        display: none !important;
      }
    }

    &.textarea {
      margin: 0.5rem 0;
      margin-bottom: 0.1rem;

      textarea {
        resize: none;
        height: 3em;
        overflow-y: hidden;
        background-color: transparent;
        padding: 0em 0.5em;

        color: var(--tertiary_text_color);
        font-size: 12px;
        font-family: "poppins";
        border: none !important;
        outline: none !important;

        &::placeholder {
          color: var(--tertiary_text_color) !important;
        }
      }

      &.--grey {
        textarea {
          color: var(--secondary_text_color) !important;
          font-size: 14px;
          font-family: "poppins";

          &::placeholder {
            color: #cdcdcd !important;
          }
        }
      }
      // input:-webkit-autofill,
      // input:-webkit-autofill:hover,
      // input:-webkit-autofill:focus,
      // input:-webkit-autofill:active {
      //     caret-color: #f8f8f8;
      //     -webkit-text-fill-color: #f8f8f8;
      //     -webkit-background-clip: text;
      //     transition: background-color 5000s ease-in-out 0s;
      //     border: 1.5px solid var(--primary_background_color) !important;
      // }

      &.--disabled {
        opacity: 0.7;
        background-color: #f1f1f1;

        .form-outline {
          .form-label {
            background-color: #f1f1f1;
          }
        }
      }

      &.--error {
        border: 2px solid #ff5c5c6b;

        .form-outline {
          .form-label {
            color: #ff5c5c !important;
          }
          .form-control {
            color: #ff5c5c;
          }
        }
      }

      .form-notch {
        display: none !important;
      }
    }
  }
  &._checkbox {
    color: #afc7dd;
    font-family: "cabin";
    font-size: 14px;

    .form-label {
      color: #333333 !important;
    }

    .checkbox-wrapper {
      --borderColor: #6c5fe6;
      --borderWidth: 0.125em;
      --checkedColor: #6c5fe6;
    }

    .checkbox-wrapper input[type="checkbox"] {
      -webkit-appearance: none;
      appearance: none;
      vertical-align: middle;
      background: transparent;
      font-size: 1.3em;
      border-radius: 0.125em;
      display: inline-block;
      border: var(--borderWidth) solid var(--borderColor);
      width: 0.9em;
      height: 0.9em;
      position: relative;
    }
    .checkbox-wrapper input[type="checkbox"]:before,
    .checkbox-wrapper input[type="checkbox"]:after {
      content: "";
      position: absolute;
      background: var(--checkedColor);
      width: calc(var(--borderWidth) * 1.8);
      height: var(--borderWidth);
      top: 45%;
      left: 10%;
      transform-origin: left center;
    }
    .checkbox-wrapper input[type="checkbox"]:before {
      transform: rotate(45deg)
        translate(calc(var(--borderWidth) / -2), calc(var(--borderWidth) / -2))
        scaleX(0);
      transition: transform 100ms ease-in 100ms;
    }
    .checkbox-wrapper input[type="checkbox"]:after {
      width: calc(var(--borderWidth) * 5);
      transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2))
        scaleX(0);
      transform-origin: left center;
      transition: transform 100ms ease-in;
    }
    .checkbox-wrapper input[type="checkbox"]:checked:before {
      transform: rotate(45deg)
        translate(
          calc(var(--borderWidth) / -4.8),
          calc(var(--borderWidth) / -2.4)
        )
        scaleX(1);
      transition: transform 100ms ease-in;
    }
    .checkbox-wrapper input[type="checkbox"]:checked:after {
      width: calc(var(--borderWidth) * 4);
      transform: rotate(-45deg) translateY(calc(var(--borderWidth) * 2))
        scaleX(1);
      transition: transform 100ms ease-out 100ms;
    }
    .checkbox-wrapper input[type="checkbox"]:focus {
    }
  }
  &._dropdown {
    width: 22em;
    font-size: 14px;
    font-family: var(--primary_font);

    @media screen and (max-width: 991px) {
      width: 100%;
    }

    &.--active {
      .dropdown {
        button {
          background: transparent;
        }
      }
    }

    &.--no-option {
      .dropdown {
        opacity: 0.6 !important;

        button:after {
          border-top: 0;
        }
      }
    }

    .form-label {
      min-width: 20%;
      text-align: left !important;
      color: var(--secondary_font_color) !important;

      @media (max-width: 550px) {
        width: 100%;
        margin-bottom: 0.5em !important;
      }
    }

    .dropdown {
      .jpj-compliance {
        font-size: 12px;
        color: #c5c6cd;
        width: fit-content !important;

        @media (max-width: 550px) {
          font-size: 10px;
        }
      }

      .dropdown-label,
      .active-label {
        // color: #f73838;
        width: unset !important;
        font-family: "montserrat-medium";
      }

      .btn:disabled {
        opacity: 1 !important;
      }

      button {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: 100%;
        font-size: 12px;
        font-family: var(--primary_font);
        border-radius: 5px;
        text-transform: capitalize;
        box-shadow: none !important;
        border: 1px solid var(--primary_font_color);
        padding: 10px;
        background: #3c3d45;

        p {
          text-align: left;
          width: 100%;
        }
      }

      button[aria-expanded="true"] {
        border-color: transparent;

        p {
          color: transparent;
        }
        // border-bottom-left-radius: 0;
        // border-bottom-right-radius: 0;
      }

      button:after {
        margin-right: 0.6em;
        box-shadow: unset;
        border-top: 0.4em solid var(--primary_font_color);
      }

      .dropdown-menu {
        z-index: 30;
        width: 100%;
        overflow: hidden;
        inset: 0px auto auto 0px !important;
        transform: translate(0px, -2px) !important;
        box-shadow: unset;
        // background: transparent;
        border: 1px solid #000000;
        background: var(--secondary_background_color);

        .divider {
          margin: 0 6px 0 28px;
          border-color: #dbdbdb;
          opacity: 1;
        }

        li {
          width: 100%;
          display: flex;
          color: #707070;
          font-size: 12px;
          font-family: var(--primary_font);
          padding: 10px 1em 10px 30px;

          &:first-child {
            padding-left: 0;
            align-items: center;

            svg {
              margin: 0 5px 1px 10px;
              width: 12px;
              height: 12px;
              color: var(--primary_font_color);
            }
          }
        }

        li:hover {
          color: #333333;
          cursor: pointer;
          opacity: 0.8;
          // background-color: #3c3d45;
        }
      }
    }
  }
  &._radio {
    font-size: 14px;
    font-family: var(--primary_font);

    .form-label {
      color: #333333 !important;
    }

    .radio-buttons {
      display: flex;
      flex-direction: column;
      color: white;
    }

    .radio-button {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .radio-button input[type="radio"] {
      display: none;
    }

    .radio-circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid #cacaca;
      position: relative;
      margin-right: 10px;
    }

    .radio-circle::before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #cacaca;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: all 0.2s ease-in-out;
    }

    .radio-button input[type="radio"]:checked + .radio-circle::before {
      transform: translate(-50%, -50%) scale(1);
    }

    .radio-button input[type="radio"]:checked + .radio-circle::before {
      background-color: #acacac;
    }

    .radio-label {
      font-size: 16px;
      font-weight: bold;
    }

    .radio-button:hover .radio-circle {
      border-color: #acacac;
    }

    .radio-button:hover input[type="radio"]:checked + .radio-circle::before {
      background-color: rgb(133, 133, 133);
    }
  }
  &._errors {
    margin: 0.25em 0em;
    // margin-left: 0em;
    font-size: 13px !important;
    color: #ff432a;
    letter-spacing: 0.03em;
    font-family: "arial" !important;

    span {
      cursor: pointer;
      border-bottom: 1px solid #ff432a;
      color: #ffffff;
    }

    @media (max-width: 550px) {
      font-size: 12px !important;
    }
  }
}

.common {
  &._symbol-line {
    margin: 1.2em 0em;

    &.--history {
      .line {
        border-color: var(--tertiary_text_color);
      }

      .symbol {
        color: var(--tertiary_text_color) !important;
      }
    }

    .line {
      opacity: 0.8;
      border-bottom: 1px solid var(--secondary_text_color);
      width: 100%;

      display: flex;
      align-items: center;

      h1 {
        opacity: 1;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        background: #ffffff;
        padding: 0 0.3em;

        font-size: 24px;
        font-family: "poppins-medium";
        color: #0f0e0e;
        text-transform: uppercase;
        margin-top: 0.2em;
      }

      .symbol {
        position: absolute;
        font-size: 16px;
        color: var(--secondary_text_color);
        margin-top: 0.1em;

        &.--left {
          left: -1%;
        }

        &.--right {
          right: -1%;
        }
      }
    }

    &.--children {
      .line {
        .symbol {
          &.--left {
            left: -1.5%;
          }

          &.--right {
            right: -1.5%;
          }
        }
      }
    }
  }
}

.go2072408551 {
  padding: 0.5em 2em 0.5em 0.8em !important;
  border-radius: 6px !important;
}

.toast-bar {
  min-width: 25em;
  min-height: 5em;
  display: flex;
  align-items: center;
  gap: 1.25em;

  .toast-divider {
    height: 100%;
    min-height: 5.5em;
    width: 0.6em;
    border-radius: 10px;
  }

  h5,
  p {
    margin: 0;
  }

  h5 {
    text-transform: capitalize;
    font-size: 15px;
    font-family: "montserrat-semibold";
  }

  p {
    font-size: 13px;
    font-family: "montserrat-medium";
    color: #000000da;

    div {
      margin: 0;
      display: block;
    }
  }

  svg {
    width: 14px;
    height: 14px;
    color: var(--secondary_font_color);
  }

  &.--success {
    .toast-divider {
      background: #aad379;
    }

    h5 {
      color: #7baf40;
    }
  }

  &.--error {
    .toast-divider {
      background: #f16b68;
    }

    h5 {
      color: #fb4642;
    }
  }

  &.--warning {
    .toast-divider {
      background: #ffdb6b;
    }

    h5 {
      color: #ffca28;
    }
  }
}

.p-skeleton {
  border: 0 !important;
  background: #3d3e47 !important;

  &::after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(79, 81, 89, 0.68),
      rgba(255, 255, 255, 0)
    ) !important;
  }
}

.ant-picker {
  background-color: #f4f4f421;
  padding: 0.9em 1.5em;
  color: var(--primary_font_color);
  font-size: 14px;
  font-family: "arial";
  border: none !important;
  outline: none !important;
  border-radius: 28px;
  letter-spacing: 0.02em;
  max-width: 28.5em;
  width: 100%;

  &:focus {
    background-color: #f4f4f421 !important;
    color: var(--primary_font_color) !important;
    box-shadow: 0 0 0 2px rgba(76, 91, 103, 0.1) !important;
  }

  &:hover {
    background-color: #f4f4f421 !important;
  }

  input {
    &::placeholder {
      color: #7e7e7e !important;
    }
  }
}

.ant-picker-outlined:focus-within {
  box-shadow: 0 0 0 2px rgba(76, 91, 103, 0.1) !important;
}

.styles-module_tooltip__mnnfp {
  padding: 6px 13px !important;
  background-color: var(--primary_modal_color) !important;
  color: #f8f8f8 !important;
  font-size: 10px !important;
  font-family: poppins !important;
  width: 98% !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.pre {
  white-space: pre !important;
}

.line-height-20 {
  line-height: 20px;

  @media screen and (max-width: 800px) {
    line-height: 15px;
  }
}

.one-line {
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}

.two-line {
  display: -webkit-inline-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}

//pointer

.not-allowed {
  cursor: not-allowed;
}

.pointer {
  cursor: pointer;
}

.default {
  cursor: default;
}

//grid

.grid {
  display: grid;
}

.end {
  place-items: end;
  justify-items: end;
}

.center {
  place-items: center;
  justify-items: center;
}

//flex

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: start;
}

.items-center {
  align-items: center !important;
}

.items-end {
  align-items: end;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: start !important;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: end;
}

.justify-between {
  justify-content: space-between;
}

//position

.relative {
  position: relative;
}

.underline {
  text-decoration: underline;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-unset {
  overflow: unset !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.hide {
  display: none !important;
}

img {
  min-width: 1px;
  min-height: 1px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.colspan-3 {
  grid-column: span 3;
}

.flex-column {
  flex-direction: column;
}

.row-reverse {
  flex-direction: column;
}

.minh-480 {
  min-height: 480px;
}

.w-20 {
  width: 20%;
}

.w-80 {
  width: 80% !important;
}

.w-100 {
  width: 100%;
}

.gap-8px {
  gap: 8px;
}

.transparent {
  color: transparent !important;
}
