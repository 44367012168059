@import "../abstract/variables";

.login-button, .talk-button, .profile-button, .common-button {
    transition: none !important;
    box-shadow: 0 0.3em 0 0 #fb331899 !important;

    &:active, &:focus-visible {
        transform: translateY(0.3em);
        box-shadow: none !important;
    }
} 

.modal-footer {
    &.--disabled {
        box-shadow: 0 0.4em 0 0 #d16065 !important;
    }

    &.mobile {
        transition: none !important;
        box-shadow: 0 0.4em 0 0 #fb331899;

        &:active, &:focus-visible {
            transform: translateY(0.4em);
            box-shadow: none !important;
        }
    }
}

.common-package {
    .swiper {
        padding: 2.5em 3em !important;
    }

    .package-detail {
        position: relative;
    }

    .package-detail:before, .package-detail:after {
        content:"";
        position: absolute;
        margin-top: 0.25em;
        width: 100%;
        height: 98%;
        border-radius: 10px;
        background: #ffffff40;
        transition: 0.5s;
        z-index: -1;
    }
    
    .package-detail:hover:before {
        transform: rotate(20deg);
        box-shadow: 0 2px 20px rgba(0,0,0,.2);
    }
    
    .package-detail:hover:after {
        transform: rotate(10deg);
        box-shadow: 0 2px 20px rgba(0,0,0,.2);
    }
}

.ppf-package, .coating-package {
    .package-list {
        .package-item {
            transform-origin: center;
            transform: scale(1) translateZ(0);
            transition: 
            filter 200ms linear,
            transform 200ms linear;
            filter: none; /* Default state: no filter */
        }

        /* Scale the hovered package and don't apply any filter */

        &:hover {
            .package-item:hover {
                transform: scale(1.05) translateZ(0);
                filter: none; /* No filter on the hovered item */
            }
        
            .package-item:not(:hover) {
                filter: brightness(0.8) saturate(1) contrast(1) blur(8px);
            }
        }
    }
}

.accessories-item, .product-item {
    .accessories-frame, .product-frame {
        overflow: hidden;
    
        img {
            transition: transform 0.8s ease;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

// .service-item {
//     span.shape {
//         height: 60px;
//         width: 140px;
//         position: absolute;
//         top: 0;
//         left: -10px;
//     }

//     span span {
//         content:"";
//         position: absolute;
//         margin: auto;
//         height: 8px;
//         width: 2px;
//         background: #E6203B;
//         opacity: 0;
//     }
  
//     span span:nth-of-type(1) {
//         top: -8px;
//         left: 0;
//         right: 0;
//     }
    
//     span span:nth-of-type(2) {
//         bottom: -40px;
//         left: 0;
//         right: 0;
//     }
    
//     span span:nth-of-type(3) {
//         top: 22px;
//         bottom: 0;
//         left: 2px;
//     }
    
//     span span:nth-of-type(4) {
//         top: 22px;
//         bottom: 0;
//         right: 2px;
//     }
    
//     span span:nth-of-type(5) {
//         top: -5px;
//         left: -70%;
//         right: 0;
//         transform:rotate(-30deg);
//     }
      
//     span span:nth-of-type(6) {
//         top: -5px;
//         left: 0;
//         right: -70%;
//         transform:rotate(30deg);
//     }
    
//     span span:nth-of-type(7) {
//         bottom: -38px;
//         right: 0;
//         left: -70%;
//         transform:rotate(30deg);
//     }
    
//     span span:nth-of-type(8) {
//         bottom: -38px;
//         left: 0;
//         right: -70%;
//         transform:rotate(-30deg);
//     }

//     &:hover {
//         animation: bounce 0.3s ease-out 1;

//         span span:nth-of-type(1) {
//             animation: anim1 0.4s ease-out 1;
//         }
        
//         span span:nth-of-type(2) {
//             animation: anim2 0.4s ease-out 1;
//         }
        
//         span span:nth-of-type(3) {
//             animation: anim3 0.4s ease-out 1;
//         }
        
//         span span:nth-of-type(4) {
//             animation: anim4 0.4s ease-out 1;
//         }
        
//         span span:nth-of-type(5) {
//             animation: anim5 0.4s ease-out 1;
//         }
        
//         span span:nth-of-type(6) {
//             animation: anim6 0.4s ease-out 1;
//         }
        
//         span span:nth-of-type(7) {
//             animation: anim7 0.4s ease-out 1;
//         }
        
//         span span:nth-of-type(8) {
//             animation: anim8 0.4s ease-out 1;
//         }
//     }
    
//     &.--active {

//     }
// }

// @keyframes bounce {
//     0%{transform: scale(1);}
//     50%{transform: scale(1.1);}
//     100%{transform: scale(1);}
// }
  
// @keyframes anim1 {
//     0%{transform: scaleY(0.5);opacity: 1;}
//     50%{transform: translateY(-10px) scaleY(1.4); opacity: 1;}
//     100%{transform: translateY(-20px) scaleY(0.5);opacity: 1;}
// }
// @keyframes anim2 {
//     0%{transform: scaleY(1);opacity: 1;}
//     50%{transform: translateY(10px) scaleY(1.4); opacity: 1;}
//     100%{transform: translateY(20px) scaleY(0.5);opacity: 1;}
//     }
// @keyframes anim3 {
//     0%{transform: rotate(90deg) scaleX(0.5);opacity: 1;}
//     50%{transform: rotate(90deg) translateY(10px) scaleX(1.4); opacity: 1;}
//     100%{transform: rotate(90deg) translateY(20px) scaleX(0.5);opacity: 1;}
// }
// @keyframes anim4 {
//     0%{transform: rotate(90deg) scaleX(0.5);opacity: 1;}
//     50%{transform: rotate(90deg) translateY(-10px) scaleX(1.4); opacity: 1;}
//     100%{transform: rotate(90deg) translateY(-20px) scaleX(0.5);opacity: 1;}
// }
// @keyframes anim5 {
//     0%{transform: rotate(-30deg) scaleY(0.5);opacity: 1;}
//     50%{transform: rotate(-30deg) translateY(-8px) scaleY(1.4); opacity: 1;}
//     100%{transform: rotate(-30deg) translateY(-16px) scaleY(0.5);opacity: 1;}
// }
// @keyframes anim6 {
//     0%{transform: rotate(30deg) scaleY(0.5);opacity: 1;}
//     50%{transform: rotate(30deg) translateY(-8px) scaleY(1.4); opacity: 1;}
//     100%{transform: rotate(30deg) translateY(-16px) scaleY(0.5);opacity: 1;}
// }
// @keyframes anim7 {
//     0%{transform: rotate(30deg) scaleY(0.5);opacity: 1;}
//     50%{transform: rotate(30deg) translateY(8px) scaleY(1.4); opacity: 1;}
//     100%{transform: rotate(30deg) translateY(16px) scaleY(0.5);opacity: 1;}
// }
// @keyframes anim8 {
//     0%{transform: rotate(-30deg) scaleY(0.5);opacity: 1;}
//     50%{transform: rotate(-30deg) translateY(8px) scaleY(1.4); opacity: 1;}
//     100%{transform: rotate(-30deg) translateY(16px) scaleY(0.5);opacity: 1;}
// }