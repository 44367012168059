//mt-user
#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog {
    background: #fafafa !important;
    backdrop-filter: blur(5px) !important;

    bottom: 58px !important;

    @media (max-width: 600px) {
        bottom: 52px !important;
        right: -4px !important;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog:after {
    border-top-color: #fafafa !important;
    top: 99% !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body h1 {
    font-size: 14px;
    font-family: 'poppins-medium';
    color: var(--secondary_text_color) !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body .divider {
    border-bottom: 1px solid #d6d6d6 !important;
    margin: 0.25em -0.6em !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body .instructions p {
    font-size: 13px;
    font-family: 'poppins';
    color: var(--tertiary_text_color) !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-dialog button#unsubscribe-button {
    background: #f93b40 !important;
    color: #fafafa !important; 
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-dialog button#subscribe-button {
    background: var(--secondary_background_color) !important;
    color: #fafafa !important; 
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher .onesignal-bell-launcher-dialog button.action:hover {
    background: unset !important;
} 

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md {
    // bottom: 100px;
    // right: 20px;

    // @media screen and (min-width: 991px) {
        bottom: 30px;
        right: 15px;
    // }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-button {
    height: 48px !important;
    width: 48px !important;

    @media (max-width: 600px) {
        height: 36px !important;
        width: 36px !important;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md .onesignal-bell-launcher-message {
    right: 61px !important;
    
    @media (max-width: 600px) {
        top: 30px;
        right: 54px !important;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-message .onesignal-bell-launcher-message-body {
    font-size: 12px !important;

    @media screen and (min-width: 429px) {
        font-size: 14px !important;
    }
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-sm {
    // bottom: 50px;

    // @media screen and (min-width: 991px) {
    //     bottom: 30px;
    // }
    bottom: 30px;
    right: 15px;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog .onesignal-bell-launcher-dialog-body .push-notification {
    background: #ffffff !important;
}